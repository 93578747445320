import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { WHLoginDataService, WHSafeTranslateService } from '@workheld/workheld-shared-lib';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private safeTranslateService: WHSafeTranslateService,
    private injector: Injector,
    private loginDataService: WHLoginDataService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
    super();
  }

  handleError(error: Error) {
    super.handleError(error);
    const isLocalhost = window.location.hostname === 'localhost';
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const loginRequired = /Login required.*/i;

    if (loginRequired.test(error.message)) {
      window.location.reload();

      return;
    }

    if (chunkFailedMessage.test(error.message)) {
      this.snackBar
        // do not use translation key since it could happen before app init
        .open('Something went wrong. Please refresh the page.', 'Refresh')
        .onAction()
        .subscribe(() => {
          window.location.reload();
        });

      return;
    }

    if (this.loginDataService.activeUserDOM$ && !isLocalhost) {
      const details = {
        'User ID': this.loginDataService.activeUserDOM$?.value?.id,
        User: this.loginDataService.activeUserDOM$?.value?.formattedName,
        Tenant: this.loginDataService.activeTenantDOM$?.value?.token,
      };

      Sentry.setContext('Workheld', details);
      this._handleSentryError(error);
    } else if (!isLocalhost) {
      this._handleSentryError(error);
    }

    const toastrService = this.injector.get(ToastrService);

    if (error instanceof HttpErrorResponse) {
      let errorMessage = '';
      const err: any = error.error ? error.error : error;
      const code = err.code ? err.code : 0;

      if (err?.code) {
        errorMessage = this.safeTranslateService.instant('ERROR.' + code);
        errorMessage = errorMessage.localeCompare('ERROR.' + code)
          ? this.safeTranslateService.instant('ERROR.' + code)
          : err.message;
        if (err.affectedFields) {
          errorMessage = errorMessage.replace(/{{\w+}}/, err.affectedFields[0]);
        }
      } else if (err.message) {
        errorMessage = err.message;
      } else {
        errorMessage = `${error.message}`;
      }

      toastrService.error(errorMessage, '' + error.status);
    }
  }

  private _handleSentryError(error: any): void {
    if (error.message) {
      Sentry.captureException(new Error(error.message));
    } else if (typeof error === 'object') {
      Sentry.captureException(new Error(JSON.stringify(error)));
    } else {
      Sentry.captureException(new Error(error));
    }
  }
}
