import { UpperCasePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DateHelper } from '@bryntum/schedulerpro';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  WHDropdownComponent,
  WHDropdownModule,
  WHIconENUM,
} from '@workheld/workheld-shared-lib';
import { format, isFuture, subMinutes } from 'date-fns';

export const ALERT_OPTIONS = [
  {
    value: 0,
    subItem: null,
    translateKey: 'alert.option.workOrderStart',
  },
  {
    value: 5,
    subItem: null,
    translateKey: 'alert.option.fiveMinutesBefore',
  },
  {
    value: 15,
    subItem: null,
    translateKey: 'alert.option.fifteenMinutesBefore',
  },
  {
    value: 30,
    subItem: null,
    translateKey: 'alert.option.thirtyMinutesBefore',
  },
  {
    value: 60,
    subItem: null,
    translateKey: 'alert.option.oneHourBefore',
  },
  {
    value: 120,
    subItem: null,
    translateKey: 'alert.option.twoHoursBefore',
  },
  {
    value: 240,
    subItem: null,
    translateKey: 'alert.option.fourHoursBefore',
  },
  {
    value: 1440,
    subItem: null,
    translateKey: 'alert.option.oneDayBefore',
  },
  {
    value: 10080,
    subItem: null,
    translateKey: 'alert.option.oneWeekBefore',
  },
];

@Component({
  selector: 'app-mat-dialog-remind-technician-settings',
  standalone: true,
  imports: [
    MatIconModule,
    TranslateModule,
    WHDropdownModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UpperCasePipe,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './mat-dialog-remind-technician-settings.component.html',
  styleUrl: './mat-dialog-remind-technician-settings.component.scss',
})
export class MatDialogRemindTechnicianSettingsComponent {
  remindTechnicianFormGroup = new FormGroup({
    reminderOffsetInMinutes: new FormControl(null, Validators.required),
    reminderComment: new FormControl(''),
  });
  alertOptions = ALERT_OPTIONS;

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get deleteIcon(): string {
    return WHIconENUM.DeleteIcon as string;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      scheduledStartDate: string;
      calculatedWorkOrderStatusId: number;
      reminderComment: string;
      reminderOffsetInMinutes: number;
      hideDeleteButton: boolean;
      bulkAction: boolean;
      showNoneMessage: boolean;
    },
  ) {
    if (
      !this.data.bulkAction &&
      (this.data.calculatedWorkOrderStatusId !== 0 ||
        !isFuture(new Date(this.data.scheduledStartDate)))
    ) {
      this.remindTechnicianFormGroup.get('reminderOffsetInMinutes').disable();
      this.remindTechnicianFormGroup.disable();
    }

    this.remindTechnicianFormGroup.patchValue({
      reminderOffsetInMinutes: data.reminderOffsetInMinutes,
      reminderComment: data.reminderComment,
    });

    if (data.scheduledStartDate) {
      this.alertOptions.forEach((option) => {
        option.subItem = DateHelper.format(
          subMinutes(new Date(data.scheduledStartDate), option.value),
          'L LT',
        );
      });

      this.alertOptions = ALERT_OPTIONS.filter((option) =>
        isFuture(option.subItem),
      );
    }
  }
}
