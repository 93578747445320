<div class="w-h-status-bar-wrapper">
  <ng-container *ngIf="mainBar">
    <div
      *ngFor="let statusNumber of statusArray; let i = index"
      class="status{{ i }} length{{ statusArray.length }}"
    >
      <span>{{ statusLabel + statusNumber | translate | uppercase }}</span>
      <div
        class="entry-count"
        [style.--numOfEntries]="getEntriesCount(statusNumber)"
      ></div>
    </div>
  </ng-container>
  <ol class="w-h-status-bar" *ngIf="!mainBar">
    <li
      *ngFor="let statusNumber of statusArray; let i = index"
      class="status{{ i }} length{{ statusArray.length }}"
      [ngClass]="{ current: workObjectStatusId === statusNumber || isActive }"
    >
      <span class="element-overflow"
        >{{ statusLabel + statusNumber | translate | uppercase }}
      </span>
    </li>
  </ol>
</div>
