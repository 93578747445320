import { Component, input } from '@angular/core';

@Component({
  selector: 'w-h-dialog-open-button',
  templateUrl: './w-h-dialog-open-button.component.html',
  styleUrl: './w-h-dialog-open-button.component.scss',
})
export class WHDialogOpenButtonComponent {
  displayValue = input('');
}
