<section class="contact-customer-mat-dialog">
  <div class="detail-card-header flex--r-c gap--8 p-l--8 p-t--4 p-b--4">
    <mat-icon style="color: white">notifications</mat-icon>
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        @if (
          data.reminderOffsetInMinutes || data.reminderOffsetInMinutes === 0
        ) {
          {{ "app.ui.editNotification.label" | translate }}
        } @else {
          {{ "app.ui.createNotification.label" | translate }}
        }
      </div>
    </span>
  </div>
  <div class="contact-customer-mat-dialog-body">
    @if (remindTechnicianFormGroup.disabled) {
      <div class="messages-container flex--r-c gap--8 warn m-b--12">
        <mat-icon>warning</mat-icon>
        {{ "app.ui.remindTechnicianWarning.label" | translate }}
      </div>
    }

    @if (data.showNoneMessage) {
      <div class="messages-container flex--r-c gap--8 warn m-b--12">
        <mat-icon>warning</mat-icon>
        {{ "app.ui.noWorkOrdersMatch.label" | translate }}
      </div>
    }
    <form [formGroup]="remindTechnicianFormGroup">
      <lib-w-h-dropdown
        [valueLabel]="'app.ui.alert.label'"
        [itemKeys]="['translateKey']"
        [subItemKey]="'subItem'"
        [loading]="remindTechnicianFormGroup.disabled"
        [value]="'value'"
        [valueFormControl]="
          remindTechnicianFormGroup.get('reminderOffsetInMinutes')
        "
        [dropdownItemDomList]="alertOptions"
      >
      </lib-w-h-dropdown>

      <mat-form-field>
        <mat-label>{{
          "workobject.model.contactclient.comment" | translate
        }}</mat-label>

        <textarea [rows]="3" matInput formControlName="reminderComment">
        </textarea>
      </mat-form-field>
    </form>
  </div>
  <div class="dialog-action-wrapper" mat-dialog-actions>
    @if (
      (data.reminderOffsetInMinutes || data.reminderOffsetInMinutes === 0) &&
      !data.hideDeleteButton
    ) {
      <button
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        tabindex="-1"
        mat-dialog-close
        [mat-dialog-close]="{
          reminderOffsetInMinutes: null,
          reminderComment: null,
        }"
      >
        <i class="workheld-icons" [innerHTML]="deleteIcon"></i>
        {{ "app.delete.label" | translate | uppercase }}
      </button>
    }
    <button
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      mat-dialog-close
    >
      <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
      {{ "app.cancel.label" | translate | uppercase }}
    </button>
    <button
      color="accent"
      class="dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      [disabled]="
        remindTechnicianFormGroup.disabled ||
        remindTechnicianFormGroup.invalid ||
        data.showNoneMessage
      "
      [mat-dialog-close]="remindTechnicianFormGroup.value"
    >
      <i class="workheld-icons" [innerHTML]="saveIcon"></i>
      {{ "app.save.label" | translate | uppercase }}
    </button>
  </div>
</section>
