// ANGULAR
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialUtilModule } from './angular-material-util.module';

import { TranslateModule } from '@ngx-translate/core';

// DIRECTIVES
import { ClickOutsideDirective } from './shared-directives/click-outside.directive';
import { SidenavCollapseDirective } from './shared-directives/sidenav-collapse.directive';
import { ClickCursorDirective } from './shared-directives/click-cursor.directive';
import { WHChipListComponent } from './components/w-h-chip-list/w-h-chip-list.component';
import { WHImgAuthHeaderModule } from '@workheld/workheld-shared-lib';
import { InfiniteScrollAutoCompleteDirective } from './shared-directives/infinite-scroll-auto-complete.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WHAutocompleteScrollComponent } from './components/w-h-autocomplete-scroll/w-h-autocomplete-scroll.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OptionsScrollDirective } from './components/w-h-autocomplete-scroll/w-h-autocomplete-scroll.directive';
import { FxFlexDirective } from './shared-directives/fxFlex.directive';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { WHAutocompleteStaticComponent } from './components/w-h-autocomplete-static/w-h-autocomplete-static.component';
import { WHContactCardComponent } from './components/w-h-contact-card/w-h-contact-card.component';
import { WHStackedAvatarsComponent } from './components/w-h-stacked-avatars/w-h-stacked-avatars.component';
import { WHItemAvatarComponent } from './components/w-h-item-avatar/w-h-item-avatar.component';
import { WHBackButtonComponent } from './components/buttons/w-h-back-button/w-h-back-button.component';
import { AutocompletePositionDirective } from './components/w-h-autocomplete-scroll/w-h-autocomplete-position.directive';
import { FileDragNDropDirective } from './shared-directives/w-h-files-dnd.directive';
import { WHPipesModule } from './shared-pipes/wh-pipes.module';
import { DisplayFromUrlComponent } from './components/display-from-url/display-from-url.component';
import { WHDialogOpenButtonComponent } from './components/buttons/w-h-dialog-open-button/w-h-dialog-open-button.component';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    ClickCursorDirective,
    SidenavCollapseDirective,
    InfiniteScrollAutoCompleteDirective,
    OptionsScrollDirective,
    AutocompletePositionDirective,
    FxFlexDirective,
    WHChipListComponent,
    DisplayFromUrlComponent,
    WHContactCardComponent,
    WHBackButtonComponent,
    WHDialogOpenButtonComponent,
    WHAutocompleteScrollComponent,
    WHAutocompleteStaticComponent,
    WHStackedAvatarsComponent,
    WHItemAvatarComponent,
    FileDragNDropDirective,
  ],
  exports: [
    CommonModule,
    AngularMaterialUtilModule,
    TranslateModule,
    ClickOutsideDirective,
    ClickCursorDirective,
    SidenavCollapseDirective,
    AutocompletePositionDirective,
    WHContactCardComponent,
    WHBackButtonComponent,
    WHDialogOpenButtonComponent,
    InfiniteScrollAutoCompleteDirective,
    WHChipListComponent,
    DisplayFromUrlComponent,
    WHAutocompleteScrollComponent,
    FxFlexDirective,
    WHAutocompleteStaticComponent,
    WHStackedAvatarsComponent,
    WHItemAvatarComponent,
    FileDragNDropDirective,
    WHPipesModule,
  ],
  imports: [
    CommonModule,
    WHPipesModule,
    AngularMaterialUtilModule,
    TranslateModule,
    WHImgAuthHeaderModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    MatButtonModule,
    MatRippleModule,
  ],
})
export class AppSharedModule {}
