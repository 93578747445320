import {
  sortBySortPosition,
  WHRouteZeroENUM,
  WHIconENUM,
  IWHChecklistSectionWithCheckDTO,
  WHChecklistDefinitionDOM,
  IWHCheckDefinitionDTO,
} from '@workheld/workheld-shared-lib';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { ChecklistDefinitionAsyncService } from 'src/app/app-services-async/checklist-definition-async.service';
import { AppDialogService } from 'src/app/app-dialogs/app-dialog.service';
import { Store } from '@ngrx/store';
import { IAppStore } from 'src/app/app-stores/index.stores';
import { workheldChecklistDefinitionCrudStoreFeatureKey } from 'src/app/app-stores/w-h-checklist-definition-stores/w-h-checklist-definition-crud-store/w-h-checklist-definition-crud-store.reducer';
import { firstValueFrom, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'ng-bee-checklist-definition-detail',
  templateUrl: './checklist-definition-detail.component.html',
  styleUrls: ['./checklist-definition-detail.component.scss'],
})
export class ChecklistDefinitionDetailComponent implements OnInit, OnDestroy {
  // STATE VAR
  public isCreateOrEdit: boolean = false;
  public deleting: boolean = false;
  @Input() public descriptionRows: number = 4;

  // DOM STATE
  @Input() public displayHeader: boolean = true;
  @Input() public displayHeaderInBody: boolean = false;
  @Input() public displayActionButtons: boolean = true;
  @Input() public isExpanded: boolean;

  @Output() public onCrudModeChange: EventEmitter<
    'create' | 'update' | 'duplicate'
  > = new EventEmitter<'create' | 'update' | 'duplicate'>();
  @Output() public onEditClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public onExpandedViewChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public checklistDefinitionDOM: WHChecklistDefinitionDOM;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store<IAppStore>,
    private checklistDefinitionAsyncService: ChecklistDefinitionAsyncService,
    private appDialogService: AppDialogService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    this.onExpandedViewChange.emit(this.isExpanded);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(workheldChecklistDefinitionCrudStoreFeatureKey)
        .subscribe((checklistDefinitionDOM: WHChecklistDefinitionDOM) => {
          this.checklistDefinitionDOM = checklistDefinitionDOM;
          if (checklistDefinitionDOM?.checkDefinitions) {
            this.checklistDefinitionDOM.checkDefinitions = sortBySortPosition(
              checklistDefinitionDOM.checkDefinitions,
            );
          }
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  public editChecklistDefinition(): void {
    this.onCrudModeChange.emit('update');
    this.onEditClick.emit(true);
  }

  public async deleteChecklistDefinition(): Promise<void> {
    const confirm = await firstValueFrom(
      this.appDialogService
        .openConfirmMatDialog(
          this.checklistDefinitionDOM.status === 'ACTIVE'
            ? {
                header: 'app.deactivate.label',
                body: 'confirm.deactivate.body',
                bodyParams: {
                  object: `${this.checklistDefinitionDOM.extId} - ${this.checklistDefinitionDOM.title}`,
                },
                submitAction: 'app.deactivate.label',
                alertType: 'info',
                alert:
                  'confirmationdialog.deactivatechecklistdefinition.body.label',
              }
            : {
                header: 'app.activate.label',
                body: 'confirm.activate.body',
                bodyParams: {
                  object: `${this.checklistDefinitionDOM.extId} - ${this.checklistDefinitionDOM.title}`,
                },
                submitAction: 'app.activate.label',
              },
        )
        .afterClosed(),
    );

    if (!confirm) {
      return;
    }

    this.deleting = true;
    this.changeDetectorRef.detectChanges();
    if (this.checklistDefinitionDOM.status === 'ACTIVE') {
      this.subscriptions.push(
        this.checklistDefinitionAsyncService
          .deleteChecklistDefinitionByChecklistDefinitionId(
            this.checklistDefinitionDOM.id,
          )
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.deleting = false;
              this.changeDetectorRef.detectChanges();
              return throwError(error);
            }),
          )
          .subscribe(() => {
            this.deleting = false;
            this.checklistDefinitionDOM.status = 'INACTIVE';
            this.changeDetectorRef.detectChanges();
            this.backToOverview();
          }),
      );
    } else {
      const payload = {
        status: 'ACTIVE',
      };
      this.deleting = true;
      this.subscriptions.push(
        this.checklistDefinitionAsyncService
          .updateChecklistDefinitionByChecklistDefinitionDTO(
            this.checklistDefinitionDOM.id,
            payload as any,
          )
          .subscribe(() => {
            this.checklistDefinitionDOM.status = 'ACTIVE';
            this.deleting = false;
            this.changeDetectorRef.detectChanges();
          }),
      );
    }
  }

  public backToOverview() {
    if (history.state.data && !!history.state.data.page) {
      this.router.navigate(['/', WHRouteZeroENUM.CHECKLIST_DEFINITION], {
        queryParams: { page: history.state.data.page },
      });
    } else {
      this.router.navigate(['/', WHRouteZeroENUM.CHECKLIST_DEFINITION]);
    }
  }

  public async copyChecklistDefinition() {
    const confirm = await firstValueFrom(
      this.appDialogService
        .openConfirmMatDialog({
          header: 'checklistdefinition.ui.duplicate.label',
          body: 'confirm.duplicate.body',
          bodyParams: {
            object: `${this.checklistDefinitionDOM.extId}-${this.checklistDefinitionDOM.title}`,
          },
          alertType: 'info',
          alert: 'confirmationdialog.duplicatechecklistdefinition.body.label',
          submitAction: 'checklistdefinition.ui.duplicate.label',
        })
        .afterClosed(),
    );

    if (!confirm) {
      return;
    }
    this.onCrudModeChange.emit('duplicate');
    this.onEditClick.emit(true);
  }

  public get checkDefinitionArray(): Array<IWHCheckDefinitionDTO> {
    return this.checklistDefinitionDOM?.checkDefinitions
      ? this.checklistDefinitionDOM.checkDefinitions
      : [];
  }

  public get sectionsArray(): Array<IWHChecklistSectionWithCheckDTO> {
    return this.checklistDefinitionDOM?.sections
      ? this.checklistDefinitionDOM.sections
      : [];
  }

  public getSectionChecks(
    sectionDOM: IWHChecklistSectionWithCheckDTO,
  ): IWHCheckDefinitionDTO[] {
    return sectionDOM.checkDefinitions ? sectionDOM.checkDefinitions : [];
  }

  public get checklistDefinitionIcon(): string {
    return WHIconENUM.ChecklistDefinitionIcon as string;
  }

  public get editIcon(): string {
    return WHIconENUM.EditIcon as string;
  }

  public get backIcon(): string {
    return WHIconENUM.BackIcon as string;
  }

  public get copyChecklistDefinitionIcon(): string {
    return WHIconENUM.CopyChecklistDefinitionIcon as string;
  }

  public get deleteIcon(): string {
    return WHIconENUM.DeleteIcon as string;
  }

  public get activateIcon(): string {
    return 'activate' as string;
  }
  public get deactivateIcon(): string {
    return 'deactivate' as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
