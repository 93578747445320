<div class="contact-field flex-1 p--8" (click)="contactCrud(contactDTO)">
  <div style="display: flex; align-items: start; gap: 8px">
    <div style="min-width: 30px">
      <mat-icon class="contact-icon p-r--8">person</mat-icon>
    </div>
    <div class="flex--col gap--4" style="width: 90%">
      <span class="w-h-title html-overflow" style="max-width: 90%">
        {{
          contactDTO?.salutation
            ? CONTACT_SALUTATION[contactDTO?.salutation] + " "
            : ""
        }}
        {{
          contactDTO?.firstname
            ? contactDTO?.firstname + " " + contactDTO?.lastname
            : "-"
        }}
      </span>
      <span class="w-h-title html-overflow" style="max-width: 90%">
        {{ contactDTO?.position ? contactDTO?.position : "" }}
      </span>
      <div (click)="$event.stopPropagation()" class="html-overflow">
        <a>{{ ("contact.model.landline" | translate) + ": " }} </a>
        <a
          href="tel:{{
            contactDTO?.countryCodeLandLine + contactDTO?.landLinePhone
          }}"
          class="html-overflow"
          >{{
            contactDTO?.countryCodeLandLine
              ? contactDTO?.countryCodeLandLine + contactDTO?.landLinePhone
              : "-"
          }}</a
        >
      </div>
      <div class="html-overflow">
        <a
          (click)="$event.stopPropagation()"
          class="inline-block-width-container"
          >{{ ("contact.model.mobile" | translate) + ": " }}
        </a>
        <a
          href="tel:{{
            contactDTO?.countryCodeMobile + contactDTO?.mobilePhone
          }}"
          class="html-overflow"
        >
          {{
            contactDTO?.countryCodeMobile
              ? contactDTO?.countryCodeMobile + contactDTO?.mobilePhone
              : "-"
          }}</a
        >
      </div>
      <div (click)="$event.stopPropagation()">
        <a class="inline-block-width-container"
          >{{ ("contact.model.mail" | translate) + ": " }}
        </a>
        <a href="mailto:{{ contactDTO?.mail }}" class="html-overflow">
          {{ contactDTO?.mail ? contactDTO?.mail : "-" }}
        </a>
      </div>
    </div>
  </div>
</div>
