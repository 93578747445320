import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  IWHBomNodeFlexPropertyDTO,
  IWHFlexPropertyDefinitionDTO,
  WHFlexPropertyResultTypeENUM,
} from '@workheld/workheld-shared-lib';

export function initValueFormGroup(
  flexPropertyDefinitionDTO: IWHFlexPropertyDefinitionDTO,
): FormGroup {
  const valueFormGroup: FormGroup = new FormBuilder().group({
    valueBoolean: [null],
    valueDate: [null],
    valueDecimal: [null],
    valueString: [null],
    valueWhEnum: [null],
  });
  switch (flexPropertyDefinitionDTO.resultType) {
    case WHFlexPropertyResultTypeENUM.BOOLEAN:
      if (flexPropertyDefinitionDTO.mandatory) {
        valueFormGroup.controls['valueBoolean'].patchValue(false);
        valueFormGroup.controls['valueBoolean'].setValidators([
          Validators.required,
        ]);
      }
      return valueFormGroup;
    case WHFlexPropertyResultTypeENUM.DATE:
      if (flexPropertyDefinitionDTO.mandatory) {
        valueFormGroup.controls['valueDate'].setValidators([
          Validators.required,
        ]);
      }
      return valueFormGroup;
    case WHFlexPropertyResultTypeENUM.DECIMAL:
      if (flexPropertyDefinitionDTO.mandatory) {
        valueFormGroup.controls['valueDecimal'].setValidators([
          Validators.required,
        ]);
      }
      return valueFormGroup;
    case WHFlexPropertyResultTypeENUM.STRING:
      if (flexPropertyDefinitionDTO.mandatory) {
        valueFormGroup.controls['valueString'].setValidators([
          Validators.required,
        ]);
      }
      return valueFormGroup;
    case WHFlexPropertyResultTypeENUM.WHENUM:
      if (flexPropertyDefinitionDTO.mandatory) {
        valueFormGroup.controls['valueWhEnum'].setValidators([
          Validators.required,
        ]);
      }
      return valueFormGroup;
    default:
      return valueFormGroup;
  }
}

export function flexPropertyFormBuilder(
  flexPropertyDefinitionDTO: IWHFlexPropertyDefinitionDTO,
) {
  return new FormBuilder().group({
    flexPropertyDefinitionId: [flexPropertyDefinitionDTO.id],
    sortPosition: [flexPropertyDefinitionDTO.sortPosition],
    optionsType: [flexPropertyDefinitionDTO.optionsType],
    resultType: [flexPropertyDefinitionDTO.resultType],
    name: [flexPropertyDefinitionDTO.name],
    mandatory: [flexPropertyDefinitionDTO.mandatory],
    value: initValueFormGroup(flexPropertyDefinitionDTO),
  });
}

export function bomNodeFlePropertyFormBuilder(
  bomNodeFlexProperty: IWHBomNodeFlexPropertyDTO,
) {
  const valueFormGroup = initValueFormGroup(bomNodeFlexProperty.definition);
  valueFormGroup.patchValue(bomNodeFlexProperty.value);
  return new FormBuilder().group({
    id: [bomNodeFlexProperty.id],
    sortPosition: [bomNodeFlexProperty.definition.sortPosition],
    resultType: [bomNodeFlexProperty.definition.resultType],
    optionsType: [bomNodeFlexProperty.definition.optionsType],
    name: [bomNodeFlexProperty.definition.name],
    mandatory: [bomNodeFlexProperty.definition.mandatory],
    value: valueFormGroup,
  });
}

export function initContactFormGroup(): FormGroup {
  return new FormBuilder().group({
    company: [null, Validators.maxLength(100)],
    id: [null],
    firstname: [null, [Validators.required, Validators.maxLength(100)]],
    lastname: [null, [Validators.required, Validators.maxLength(100)]],
    mail: [null, [Validators.email, Validators.maxLength(100)]],
    notes: [null],
    position: [null, Validators.maxLength(100)],
    salutation: [null],
    countryCodeMobile: [null, Validators.maxLength(10)],
    mobilePhone: [
      null,
      [Validators.pattern('[ ()0-9]+'), Validators.maxLength(17)],
    ],
    countryCodeLandLine: [null, Validators.maxLength(10)],
    landLinePhone: [
      null,
      [Validators.pattern('[ ()0-9]+'), Validators.maxLength(90)],
    ],
  });
}

export function sortFlexProperties(
  flexProperties: IWHBomNodeFlexPropertyDTO[],
) {
  return flexProperties.sort((c1, c2) => {
    if (c1.definition.sortPosition > c2.definition.sortPosition) {
      return 1;
    }
    if (c1.definition.sortPosition < c2.definition.sortPosition) {
      return -1;
    }
    return 0;
  });
}

export function uriValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    try {
      new URL(value);
      return null;
    } catch {
      return { invalidUri: true };
    }
  };
}
