<mat-dialog-content class="mat-typography">
  <h2>{{ data.headerTranslateKey | translate: data.headerParams }}</h2>
  @if (body) {
    <p [innerHTML]="body | highlight: data.bodyParams"></p>
  }

  @if (data.alertTranslateKey) {
    <div class="messages-container" [ngClass]="data.alertType">
      <div class="flex--r-c gap--8">
        @if (data.alertType === "warn") {
          <mat-icon>warning</mat-icon>
        } @else if (data.alertType === "info") {
          <mat-icon>info</mat-icon>
        }
        <span>{{ data.alertTranslateKey | translate }}</span>
      </div>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  @if (!data.isCancelHidden) {
    <button mat-stroked-button [mat-dialog-close]="false">
      {{ "app.cancel.label" | translate }}
    </button>
  }
  <button
    data-cy="confirm-btn"
    mat-flat-button
    color="accent"
    [mat-dialog-close]="true"
  >
    {{ data.submitActionTranslateKey ?? "app.okay.label" | translate }}
  </button>
</mat-dialog-actions>
