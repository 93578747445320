<div>
  <div class="w-h-label html-overflow" *ngIf="withTimeFrameLabel">
    {{ timeFrameLabel | translate }}
  </div>
  <div
    class="date-field"
    [ngClass]="{ 'date-conflict': displayConflict && startConflict }"
  >
    <span *ngIf="isWithStartDateLabel" class="date-label">
      {{ startDateLabel | translate }}:
    </span>
    <span
      matTooltip="{{
        startDate
          ? isStartTimeVisible
            ? (startDate | date: 'short' : '' : currentLocale())
            : (startDate | date: 'shortDate' : '' : currentLocale())
          : '--'
      }}"
    >
      {{
        startDate
          ? isStartTimeVisible
            ? (startDate | date: "short" : "" : currentLocale())
            : (startDate | date: "shortDate" : "" : currentLocale())
          : "--"
      }}
    </span>
  </div>
  <div
    class="date-field"
    *ngIf="!isSingleDate"
    [ngClass]="{ 'date-conflict': endConflict }"
  >
    <span *ngIf="isWithEndDateLabel" class="date-label">
      {{ endDateLabel | translate }}:
    </span>
    <span
      matTooltip="{{
        endDate
          ? isEndTimeVisible
            ? (endDate | date: 'short' : '' : currentLocale())
            : (endDate | date: 'shortDate' : '' : currentLocale())
          : '--'
      }}"
    >
      {{
        endDate
          ? isEndTimeVisible
            ? (endDate | date: "short" : "" : currentLocale())
            : (endDate | date: "shortDate" : "" : currentLocale())
          : "--"
      }}
    </span>
  </div>
</div>
