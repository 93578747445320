import {
  updateObject,
  WHIconENUM,
  WHNgxToastrENUM,
  IWHChecklistInstanceSectionWithCheckDTO,
  WHNgxToastrService,
} from '@workheld/workheld-shared-lib';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

// COMPONENTS
import { MatDialogCommentEntryComponent } from '../mat-dialog-comment-entry/mat-dialog-comment-entry.component';

// ANGULAR MATERIAL
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';

// SERVICES
import { ChecklistInstanceAsyncService } from 'src/app/app-services-async/checklist-instance-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

// MODELS
import {
  IWHChecklistInstanceDTO,
  WHChecklistInstanceDOM,
} from '@workheld/workheld-shared-lib';
import { checklistInstanceStatusIdEnumArray } from '@workheld/workheld-shared-lib';
import { AppDialogService } from '../app-dialog.service';

const checklistInstanceStatuses: ReadonlyArray<boolean[]> = [
  [true, true, true, true],
  [false, true, false, false],
  [true, true, true, false],
  [true, true, true, true],
];

@Component({
  selector: 'ng-bee-mat-dialog-checklist-instance-detail-entry',
  templateUrl: './mat-dialog-checklist-instance-detail-entry.component.html',
  styleUrls: ['./mat-dialog-checklist-instance-detail-entry.component.scss'],
})
export class MatDialogChecklistInstanceDetailEntryComponent
  implements OnInit, OnDestroy
{
  // ENUMS
  public MEASUREMENT_TYPE: any;
  public UNIT: any;

  // DATA VAR
  public checklistInstanceProcessFormGroup: FormGroup;
  public checklistInstanceDOM: WHChecklistInstanceDOM;
  public checklistInstanceStatusIdEnumArray: number[] =
    checklistInstanceStatusIdEnumArray;

  // STATE
  public saving: boolean = false;

  // MANAGE SUBSCRIPTIONS
  public subscriptions: Subscription[] = [];

  constructor(
    private checklistInstanceAsyncService: ChecklistInstanceAsyncService,
    private dataModelHelperService: DataModelHelperService,
    private matDialog: MatDialog,
    public matDialogRef: MatDialogRef<MatDialogChecklistInstanceDetailEntryComponent>,
    private ngxToastrService: WHNgxToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { checklistInstanceId: string },
    private translateService: TranslateService,
    private formReferenceService: FormReferenceService,
    private appDialogService: AppDialogService,
  ) {}

  public ngOnInit(): void {
    this.checklistInstanceProcessFormGroup =
      this.checklistInstanceAsyncService.initChecklistInstanceProcessFormGroup();

    // DATA
    this.subscriptions.push(
      this.checklistInstanceAsyncService
        .getChecklistInstanceDtoByChecklistInstanceId(
          this.data.checklistInstanceId,
        )
        .subscribe((checklistInstanceDTO: IWHChecklistInstanceDTO) => {
          this.checklistInstanceDOM =
            this.dataModelHelperService.initChecklistInstanceDOM(
              checklistInstanceDTO,
            );
          this.checklistInstanceProcessFormGroup.patchValue(
            this.checklistInstanceDOM,
          );
          console.log(this.checklistInstanceDOM);
        }),
    );
  }

  public handleSubmit(): void {
    const checklistInstancePayload: IWHChecklistInstanceDTO = {
      managerComment: this.managerComment,
      checklistInstanceStatusId: this.checklistInstanceStatusId,
    } as any;

    this.saving = true;
    this.subscriptions.push(
      this.checklistInstanceAsyncService
        .updateChecklistInstanceByChecklistInstanceID(
          this.checklistInstanceDOM.id,
          checklistInstancePayload,
        )
        .subscribe((checklistInstanceDTO: IWHChecklistInstanceDTO) => {
          this.saving = false;
          const checklistInstanceDOM: WHChecklistInstanceDOM =
            this.dataModelHelperService.initChecklistInstanceDOM(
              checklistInstanceDTO,
            );
          this.checklistInstanceDOM = updateObject(
            this.checklistInstanceDOM,
            checklistInstanceDOM,
          );
          this.formReferenceService.unsavedChanges = false;

          this.matDialogRef.close(this.checklistInstanceDOM);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey:
              'checklistinstance.ui.updatesuccess.notification',
          });
        }),
    );
  }

  public previewComment(comment: string): void {
    this.matDialog.open(MatDialogCommentEntryComponent, {
      width: '40%',
      data: {
        comment,
      },
      closeOnNavigation: true,
      disableClose: false,
    } as MatDialogConfig);
  }

  public cancel(): void {
    if (this.checklistInstanceProcessFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog();
      this.subscriptions.push(
        ref
          .pipe(
            tap((canDiscard) => {
              if (canDiscard) {
                this.matDialogRef.close();
              }
            }),
          )
          .subscribe(),
      );
    } else {
      this.matDialogRef.close();
    }
  }

  public async updateChecklistInstanceStatus(): Promise<void> {
    if (
      this.checklistInstanceDOM.checklistInstanceStatusId ===
      this.checklistInstanceStatusId
    ) {
      return;
    }
  }

  public async resetToDraft(): Promise<void> {
    if (
      this.isChecklistInstanceInStatusCreate ||
      this.isChecklistInstanceInStatusClosed
    ) {
      return;
    }

    const confirm = await this.appDialogService
      .openConfirmMatDialog({
        header: 'confirmationdialog.resetchecklistinstancetodraft.header.label',
        body: 'confirmationdialog.resetchecklistinstancetodraft.title.label',
        alertType: 'info',
        alert: 'confirmationdialog.resetchecklistinstancetodraft.body.label',
        submitAction:
          'confirmationdialog.resetchecklistinstancetodraft.header.label',
      })
      .afterClosed()
      .toPromise()
      .then((res) => {
        return res;
      });

    // SET TO DRAFT - CREATE
    if (confirm) {
      this.checklistInstanceProcessFormGroup.controls[
        'checklistInstanceStatusId'
      ].patchValue(0);
    }
  }

  public get sections(): IWHChecklistInstanceSectionWithCheckDTO[] {
    return this.checklistInstanceDOM?.sections
      ? this.checklistInstanceDOM.sections
      : [];
  }

  public get checklistInstanceStatusId(): number {
    return this.checklistInstanceProcessFormGroup.controls[
      'checklistInstanceStatusId'
    ].value as number;
  }

  public get managerComment(): string {
    return this.checklistInstanceProcessFormGroup.controls['managerComment']
      .value as string;
  }

  public get isChecklistInstanceInStatusCreate(): boolean {
    return (
      this.checklistInstanceDOM &&
      this.checklistInstanceDOM.checklistInstanceStatusId === 0
    );
  }

  public get isChecklistInstanceInStatusClosed(): boolean {
    return (
      this.checklistInstanceDOM &&
      this.checklistInstanceDOM.checklistInstanceStatusId === 3
    );
  }

  public get checklistDefinitionIcon(): string {
    return WHIconENUM.ChecklistDefinitionIcon as string;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get localeValue(): string {
    return this.translateService.defaultLang;
  }

  public isDisabled(statusId: number): boolean {
    if (this.checklistInstanceStatusId) {
      return checklistInstanceStatuses[this.checklistInstanceStatusId][
        statusId
      ];
    } else {
      return true;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
