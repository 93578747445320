// ANGULAR
import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
  OnDestroy,
  Input,
} from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import {
  WHNgxToastrENUM,
  WHNgxToastrService,
} from '@workheld/workheld-shared-lib';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import { IWHLocationDTO } from '@workheld/workheld-shared-lib';
import {
  WHFlowMetadataDOM,
  WHMetadataDataService,
} from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-w-h-agm-core-search-util',
  templateUrl: './w-h-agm-core-search-util.component.html',
  styleUrls: ['./w-h-agm-core-search-util.component.scss'],
})
export class WHAgmCoreSearchUtilComponent implements OnInit, OnDestroy {
  // OUTPUT
  @Output() public onPlaceChanged: EventEmitter<IWHLocationDTO> =
    new EventEmitter();

  // DOM
  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;

  // VAR
  private supportedCountryIsoEnums: string[] = [];

  // INPUT STATE
  @Input() public tabIndex: number = 0;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private metadataDataService: WHMetadataDataService,
    private ngxToastrService: WHNgxToastrService,
    private dataModelHelperService: DataModelHelperService,
  ) {}

  public ngOnInit(): void {
    // ENUM COUTRIES
    // METADATA - LOCATION ENUMS
    this.subscriptions.push(
      this.metadataDataService.metadataDOM$.subscribe(
        (metadataDOM: WHFlowMetadataDOM) => {
          this.supportedCountryIsoEnums =
            metadataDOM && metadataDOM.countries ? metadataDOM.countries : [];
        },
      ),
    );

    // GOOGLE SEARCH
    const autocomplete: google.maps.places.Autocomplete =
      new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // CLEAR SEARCH
        const formattedAddress: string =
          this.searchElementRef.nativeElement.value;
        this.searchElementRef.nativeElement.value = null;
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        // console.log(place);
        if (place.geometry) {
          const locationDTO: IWHLocationDTO =
            this.dataModelHelperService.initLocationDtoFromGooglePlaceResult(
              place,
            );
          const supportedCountryISO: string =
            this.supportedCountryIsoEnums.find((countryISO: string) => {
              return (
                locationDTO.countryCodeISO &&
                countryISO.toLowerCase() ===
                  locationDTO.countryCodeISO.toLowerCase()
              );
            });
          // console.log(location);
          if (!!supportedCountryISO) {
            this.onPlaceChanged.emit(locationDTO as any);
          } else {
            if (
              !this.supportedCountryIsoEnums ||
              this.supportedCountryIsoEnums.length === 0
            ) {
              // ENUMS NOT SET!
              this.ngxToastrService.displayToastr({
                toastrType: WHNgxToastrENUM.WARNING,
                messageTranslateKey: 'ERROR.COUNTRIES_NOT_SET',
              });
            } else {
              this.ngxToastrService.displayToastr({
                toastrType: WHNgxToastrENUM.INFO,
                messageTranslateKey: 'ERROR.CATALOG_BR_LOCATION_2',
              });
            }
          }
          return;
        } else {
          this.onPlaceChanged.emit({
            formattedAddress,
          } as any);
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
