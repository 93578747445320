<form class="mat-dialog-add-shift-group-container">
  <div class="detail-card-header mat-dialog-add-content">
    <span whFlex class="w-h-header-span">
      <div class="single-header" *ngIf="data.shiftGroup">
        {{ "shift.label.updateShiftGroup" | translate }}
      </div>
      <div class="single-header" *ngIf="!data.shiftGroup">
        {{ "shift.button.createGroup" | translate }}
      </div>
    </span>
  </div>
  <div class="mat-dialog-add-shift-content">
    <mat-stepper [@.disabled]="true" [linear]="true" #stepper>
      <mat-step [stepControl]="shiftGroupFormGroup">
        <form [formGroup]="shiftGroupFormGroup">
          <ng-template matStepLabel>{{
            "shift.label.shiftGroup" | translate
          }}</ng-template>
          <p>{{ "shift.label.shiftGroupDescription" | translate }}</p>
          <div class="flex--r-c gap--16 p-b--16">
            <mat-form-field class="flex-1">
              <mat-label>{{ "shift.model.extId" | translate }}</mat-label>
              <input matInput formControlName="extId" />
            </mat-form-field>

            <mat-form-field class="flex-1">
              <mat-label>{{
                "shift.label.shiftGroupName" | translate
              }}</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>

            <mat-form-field class="flex-1">
              <mat-label>{{ "shift.model.status" | translate }}</mat-label>
              <mat-select formControlName="status">
                <mat-option
                  *ngFor="let statusId of statusIdEnumArray"
                  [value]="statusId"
                >
                  {{ "material.enum.status." + statusId | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex--r-c-r gap--16">
            <button
              class="w-h-default-button dialog-button mat-elevation-z2"
              mat-flat-button
              type="button"
              (click)="cancel()"
            >
              {{ "app.cancel.label" | translate | uppercase }}
            </button>

            <button
              color="accent"
              class="dialog-button mat-elevation-z2"
              [disabled]="shiftGroupFormGroup.invalid"
              mat-flat-button
              type="button"
              matStepperNext
            >
              {{ "app.next.label" | translate | uppercase }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="shiftModelsFormGroup"
        label="{{ 'shift.label.shiftModels' | translate }}"
      >
        <p>
          {{ "shift.label.shiftModelsDescription" | translate }}
        </p>
        <form [formGroup]="shiftModelsFormGroup" autocomplete="off">
          <ng-container formArrayName="shiftModels">
            <div class="overflow-wrapper">
              <ng-container
                *ngFor="let shiftModel of shiftModels.controls; let i = index"
                class="shift-models-container"
              >
                <app-shift-model-fields
                  [shiftModelsFormGroup]="getModelByIndex(i)"
                  [canDelete]="true"
                  (onDelete)="deleteShiftModel(i)"
                ></app-shift-model-fields>
              </ng-container>
              <div>
                {{ shiftModelsFormGroup.errors }}
              </div>

              <div
                *ngIf="shiftModels.controls.length === 0"
                class="align-text-center"
              >
                {{ "shift.label.noShiftModels" | translate }}
              </div>
            </div>

            <div class="flex--r-c-r flex-1 p-t--16 p-b--16">
              <button
                class="w-h-default-button dialog-button mat-elevation-z2"
                mat-flat-button
                type="button"
                (click)="addShiftModel()"
              >
                {{ "shift.button.addShiftModel" | translate }}
              </button>
            </div>
            <div
              *ngIf="shiftModels.errors"
              class="messages-container warn m--8"
            >
              <div>{{ "error.formValidation.title" | translate }}</div>
              <ul>
                <li *ngIf="shiftModels.errors?.duplicateName">
                  <mat-error>
                    {{ "error.formValidation.duplicateName" | translate }}
                  </mat-error>
                </li>
                <li *ngIf="shiftModels.errors?.overlappingTime">
                  <mat-error>
                    {{ "error.formValidation.overlappingError" | translate }}
                  </mat-error>
                </li>
                <li *ngIf="shiftModels.errors?.isSameHour">
                  <mat-error>
                    {{ "error.formValidation.sameHourShiftError" | translate }}
                  </mat-error>
                </li>
              </ul>
            </div>
          </ng-container>

          <div class="flex--r-c-r gap--16">
            <button
              class="w-h-default-button dialog-button mat-elevation-z2"
              mat-flat-button
              type="button"
              (click)="cancel()"
            >
              {{ "app.cancel.label" | translate | uppercase }}
            </button>

            <button
              class="dialog-button mat-elevation-z2"
              mat-flat-button
              type="button"
              matStepperPrevious
            >
              {{ "app.back.label" | translate | uppercase }}
            </button>
            <button
              [disabled]="shiftModelsFormGroup.invalid"
              color="accent"
              class="dialog-button mat-elevation-z2"
              mat-flat-button
              type="button"
              matStepperNext
            >
              {{ "app.next.label" | translate | uppercase }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>{{
          "app.summary.label" | translate
        }}</ng-template>

        <div class="flex--r-fs gap--32 flex--wrap">
          <div class="flex--c-c-l gap--16">
            <!-- Title text for Shiftgroup-->
            <div class="title" id="shiftgrouptitle">
              {{ "shift.label.shiftGroup" | translate }}:
            </div>

            <table
              class="shift-group-page-table"
              aria-describedby="shiftgrouptitle"
            >
              <tr>
                <th>{{ "shift.model.extId" | translate }}</th>
                <th>{{ "shift.model.status" | translate }}</th>
                <th>{{ "shift.label.shiftGroupName" | translate }}</th>
              </tr>
              <tr>
                <td>{{ shiftGroupFormGroup.value.extId }}</td>
                <td>
                  {{
                    "material.enum.status." + shiftGroupFormGroup.value.status
                      | translate
                  }}
                </td>
                <td>{{ shiftGroupFormGroup.value.name }}</td>
              </tr>
            </table>
          </div>

          <div class="flex--c-c-l gap--16">
            <!--   Title text Shiftmodels-->
            <div class="title" id="shiftmodeltitle">
              {{ "shift.label.shiftModels" | translate }}:
            </div>

            <table
              class="detail-page-table"
              *ngIf="shiftModels.value.length > 0"
              aria-describedby="shiftmodeltitle"
            >
              <tr>
                <th>{{ "shift.model.name" | translate }}</th>
                <th>{{ "shift.model.breakDuration" | translate }}</th>
                <th>{{ "shift.model.startTime" | translate }}</th>
                <th>{{ "shift.model.endTime" | translate }}</th>
                <th>{{ "shift.model.status" | translate }}</th>
              </tr>
              <tr *ngFor="let shiftModel of shiftModels.value">
                <td>{{ shiftModel.name | truncate: 15 }}</td>
                <td>{{ shiftModel.breakDuration }}</td>
                <td>
                  {{
                    shiftModel.startTime?.time
                      ? shiftModel.startTime?.time
                      : shiftModel.startTime
                  }}
                </td>
                <td>
                  {{
                    shiftModel.endTime?.time
                      ? shiftModel.endTime?.time
                      : shiftModel.endTime
                  }}
                </td>
                <td>
                  {{ "material.enum.status." + shiftModel.status | translate }}
                </td>
              </tr>
            </table>
            <div *ngIf="shiftModels.value.length === 0">
              {{ "shift.label.noShiftModels" | translate }}
            </div>
          </div>
        </div>
        <div class="flex--r-c-r gap--16">
          <button
            class="w-h-default-button dialog-button mat-elevation-z2"
            mat-flat-button
            type="button"
            (click)="cancel()"
          >
            {{ "app.cancel.label" | translate | uppercase }}
          </button>
          <button
            class="dialog-button mat-elevation-z2"
            mat-flat-button
            type="button"
            matStepperPrevious
          >
            {{ "app.back.label" | translate | uppercase }}
          </button>
          <button
            color="accent"
            [disabled]="!formsAreValid()"
            class="dialog-button mat-elevation-z2"
            mat-flat-button
            type="button"
            (click)="confirm()"
          >
            <mat-spinner
              style="display: inline-block"
              *ngIf="loading$ | async"
              [diameter]="18"
            >
            </mat-spinner>
            {{ "app.confirm.label" | translate | uppercase }}
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</form>
