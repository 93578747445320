// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHBryEventStoreActionTypes,
  WHBryEventStoreActions,
  SetWHBryEventDOMList,
  GetWHWorkObjectTeamPlannerEventDTOList,
  GetWHWorkOrderTeamPlannerEventDTOList,
  GetWHWorkOrderTeamPlannerCategoryEventDTOList,
} from './w-h-bry-event-store.actions';

// RxJS 6
import { forkJoin, Observable } from 'rxjs';
import { switchMap, mergeMap, map } from 'rxjs/operators';

// MODELS
import {
  FLOW_GATEWAY,
  IWHWorkObjectEndpointConfigModel,
  IWHWorkObjectEventDTO,
  IWHWorkStepEndpointConfigModel,
  IWHWorkStepEventDTO,
  WHBryEventDOM,
  WHWorkObjectDataService,
  WHWorkStepDataService,
  IWHWorkOrderEndpointConfigModel,
  IWHWorkOrderEventDTO,
  WHWorkOrderDataService,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHBryEventStoreEffects {
  GetWHWorkObjectTeamPlannerEventDTOList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHBryEventStoreActionTypes.GetWHWorkObjectTeamPlannerEventDTOList),
      switchMap((action: GetWHWorkObjectTeamPlannerEventDTOList) => {
        const endpointConfig: IWHWorkStepEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          workObjectTypeId: action.payload.params.workObjectTypeId,
        };

        const params: any = {
          departmentId: action.payload.params['departmentId'],
        };

        const workStepCall: Observable<IWHWorkStepEventDTO[]> =
          this.workStepDataService.getTeamSchedulerWorkStepDtoList(
            endpointConfig,
            params,
          );

        const workObjectCall: Observable<IWHWorkObjectEventDTO[]> =
          this.workObjectDataService.getTeamScheduleWorkObjectDtoList(
            endpointConfig as IWHWorkObjectEndpointConfigModel,
            params,
          );

        const requests: Array<Observable<any[]>> = [
          workStepCall,
          workObjectCall,
        ];

        return forkJoin(requests).pipe(
          // `response` is a server response
          map((response: any[]) => {
            const WORK_STEP_RESPONSE_INDEX = 0;
            const WORK_OBJECT_RESPONSE_INDEX = 1;
            const events: WHBryEventDOM[] = [];

            // Transform Work Steps from server response into WHBryEventDOM
            (
              response[WORK_STEP_RESPONSE_INDEX] as IWHWorkStepEventDTO[]
            ).forEach((eventDTO: IWHWorkStepEventDTO) => {
              // @workheld/workheld-shared-lib/src/lib/models-bryntum/w-h-bry-event.model.tsWHBryEventDOM::constructor
              const eventDOM: WHBryEventDOM = new WHBryEventDOM(eventDTO)
                // @workheld/workheld-shared-lib/src/lib/models-bryntum/w-h-bry-event.model.tsWHBryEventDOM::fromWorkStep
                .fromWorkStep(eventDTO);

              events.push(eventDOM);
            });

            // Transform Work Objects from server response into WHBryEventDOM
            (
              response[WORK_OBJECT_RESPONSE_INDEX] as IWHWorkObjectEventDTO[]
            ).forEach((eventDTO: IWHWorkObjectEventDTO) => {
              // @workheld/workheld-shared-lib/src/lib/models-bryntum/w-h-bry-event.model.tsWHBryEventDOM::constructor
              const eventDOM: WHBryEventDOM = new WHBryEventDOM(eventDTO)
                // @workheld/workheld-shared-lib/src/lib/models-bryntum/w-h-bry-event.model.tsWHBryEventDOM::fromWorkObject
                .fromWorkObject(eventDTO);

              events.push(eventDOM);
            });

            return events;
          }),
        );
      }),
      mergeMap((events: WHBryEventDOM[]) => {
        return [
          new SetWHBryEventDOMList({ events, loading: false, loaded: true }),
        ];
      }),
    );
  });

  GetWHWorkOrderTeamPlannerEventDTOList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHBryEventStoreActionTypes.GetWHWorkOrderTeamPlannerEventDTOList),
      switchMap((action: GetWHWorkOrderTeamPlannerEventDTOList) => {
        const endpointConfig: IWHWorkStepEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          workObjectTypeId: action.payload.params.workObjectTypeId,
        };

        const params: any = {
          departmentId: action.payload.params['departmentId'],
        };

        const workStepCall: Observable<IWHWorkStepEventDTO[]> =
          this.workStepDataService.getTeamSchedulerWorkStepDtoList(
            endpointConfig,
            params,
          );
        const workOrderCall: Observable<any[]> =
          this.workOrderDataService.getTeamScheduleWorkOrderDtoList(
            endpointConfig as IWHWorkOrderEndpointConfigModel,
            params,
          );
        const requests: Array<Observable<any[]>> = [
          workStepCall,
          workOrderCall,
        ];
        return forkJoin(requests).pipe(
          map((response: any[]) => {
            const events: WHBryEventDOM[] = [];
            (response[0] as IWHWorkStepEventDTO[]).forEach(
              (eventDTO: IWHWorkStepEventDTO) => {
                const eventDOM: WHBryEventDOM = new WHBryEventDOM(
                  eventDTO,
                ).fromWorkStep(eventDTO);
                events.push(eventDOM);
              },
            );
            (response[1] as IWHWorkOrderEventDTO[]).forEach(
              (eventDTO: IWHWorkOrderEventDTO) => {
                const eventDOM: WHBryEventDOM = new WHBryEventDOM(
                  eventDTO,
                ).fromWorkOrder(eventDTO);
                events.push(eventDOM);
              },
            );
            return events;
          }),
        );
      }),
      mergeMap((events: WHBryEventDOM[]) => {
        return [
          new SetWHBryEventDOMList({ events, loading: false, loaded: true }),
        ];
      }),
    );
  });

  GetWHWorkOrderTeamPlannerCategoryEventDTOList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHBryEventStoreActionTypes.GetWHWorkOrderTeamPlannerCategoryEventDTOList,
      ),
      switchMap((action: GetWHWorkOrderTeamPlannerCategoryEventDTOList) => {
        const endpointConfig: IWHWorkStepEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          workObjectTypeId: action.payload.params.workObjectTypeId,
        };

        const params: any = {
          departmentId: action.payload.params['departmentId'],
          categoryId: action.payload.params['categoryId'],
        };

        const workStepCall: Observable<IWHWorkStepEventDTO[]> =
          this.workStepDataService.getTeamSchedulerWorkStepDtoList(
            endpointConfig,
            params,
          );
        const workOrderCall: Observable<any[]> =
          this.workOrderDataService.getTeamScheduleCategoryWorkOrderDtoList(
            endpointConfig as IWHWorkOrderEndpointConfigModel,
            params,
          );
        const requests: Array<Observable<any[]>> = [
          workStepCall,
          workOrderCall,
        ];
        return forkJoin(requests).pipe(
          map((response: any[]) => {
            const events: WHBryEventDOM[] = [];
            (response[0] as IWHWorkStepEventDTO[]).forEach(
              (eventDTO: IWHWorkStepEventDTO) => {
                const eventDOM: WHBryEventDOM = new WHBryEventDOM(
                  eventDTO,
                ).fromWorkStep(eventDTO);
                events.push(eventDOM);
              },
            );
            (response[1] as IWHWorkOrderEventDTO[]).forEach(
              (eventDTO: IWHWorkOrderEventDTO) => {
                const eventDOM: WHBryEventDOM = new WHBryEventDOM(
                  eventDTO,
                ).fromWorkOrder(eventDTO);
                events.push(eventDOM);
              },
            );
            return events;
          }),
        );
      }),
      mergeMap((events: WHBryEventDOM[]) => {
        return [
          new SetWHBryEventDOMList({ events, loading: false, loaded: true }),
        ];
      }),
    );
  });

  constructor(
    private actions$: Actions<WHBryEventStoreActions>,
    private workObjectDataService: WHWorkObjectDataService,
    private workOrderDataService: WHWorkOrderDataService,
    private workStepDataService: WHWorkStepDataService,
  ) {}
}
