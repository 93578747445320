// HELPER
import {
  setWorkObjectTypeIdPath,
  IWHFileDTO,
  FLOW_GATEWAY,
  initHttpParams,
} from '@workheld/workheld-shared-lib';

// ENVIRONMENT
import { environment } from '../../environments/environment';
import { saveAs } from 'file-saver';

// ANGULAR
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable, of } from 'rxjs';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHFileDOM } from '@workheld/workheld-shared-lib';

export interface IWHSanitizedFileList {
  allowedFileList: File[];
  notAllowedFileExtensionList: File[];
  notAllowedFileSizeList: File[];
  notAllowedFileList: File[];
}

@Injectable()
export class WHFileAsyncService {
  constructor(private httpClient: HttpClient) {}

  private IsAllowedFileExtension(
    allowedFileExtensions: string[],
    fileName: string,
  ): boolean {
    const fileExtension = fileName.split('.').pop();
    // console.log('.' + fileExtension);
    return (
      allowedFileExtensions.indexOf('.' + fileExtension.toLowerCase()) !== -1
    );
  }

  public isAllowedMimeType(
    allowedExtensions: string[],
    mimeType: string,
  ): boolean {
    const mimeToExtensionMap: Record<string, string> = {
      'application/msword': '.doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        '.docx',
      'application/vnd.ms-excel': '.xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        '.xlsx',
      'image/jpeg': '.jpg',
      'image/png': '.png',
      'image/jpg': '.jpg',
      'image/gif': '.gif',
      'application/pdf': '.pdf',
      'image/svg+xml': '.svg',
    };

    const fileExtension = mimeToExtensionMap[mimeType];

    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }

  private isAllowedFileSize(
    maxDocumentFileSize: number,
    fileSize: number,
  ): boolean {
    return maxDocumentFileSize > fileSize ? true : false;
  }

  public sanitizeFileList(
    files: FileList,
    existingWorkOrderFileList: WHFileDOM[],
    allowedFileExtensions: string[],
    maxDocumentFileSize: number,
  ): IWHSanitizedFileList {
    const allowedFileList: File[] = [];
    const notAllowedFileExtensionList: File[] = [];
    const notAllowedFileSizeList: File[] = [];
    const notAllowedFileList: File[] = [];
    Object.values(files).forEach((file: File) => {
      // console.log(file);
      if (
        this.IsAllowedFileExtension(allowedFileExtensions, file.name) &&
        this.isAllowedFileSize(maxDocumentFileSize, file.size)
      ) {
        allowedFileList.push(file);
      } else {
        if (!this.IsAllowedFileExtension(allowedFileExtensions, file.name)) {
          notAllowedFileExtensionList.push(file);
        } else if (!this.isAllowedFileSize(maxDocumentFileSize, file.size)) {
          notAllowedFileSizeList.push(file);
        }
        notAllowedFileList.push(file);
      }
      // console.log(file.name);
    });
    return {
      allowedFileList,
      notAllowedFileExtensionList,
      notAllowedFileSizeList,
      notAllowedFileList,
    } as IWHSanitizedFileList;
  }

  // CSV PARAMS
  public initCVSParams(
    targetParent: string,
    targetID: string,
    entryTypeId: number,
  ): WHHttpParams {
    switch (targetParent) {
      case 'SUBSIDIARY':
        return {
          entryTypeId,
        };
      case 'WORK_OBJECT':
        return {
          workObjectId: targetID,
          entryTypeId,
        };
      case 'WORK_ORDER':
        return {
          workOrderId: targetID,
          entryTypeId,
        };
      default:
        return undefined;
    }
  }

  public deleteDocumentWHFile(documentId: string, type: string = 'WORK_ORDER') {
    const endpointType =
      type === 'WORK_ORDER' ? 'workorderfiles' : 'bomnodefiles';
    return this.httpClient.delete(
      `${environment.apiUrl + FLOW_GATEWAY}/${endpointType}/${documentId}`,
    );
  }

  public downloadWHFileBlob(file: IWHFileDTO | any) {
    return this.httpClient.get(file?.downloadFile, {
      headers: { 'Content-Type': file.mimeType },
      responseType: 'blob',
    });
  }

  public saveFile(file: IWHFileDTO, fileData: Blob) {
    saveAs(fileData, file.fileName + file.extension);
  }

  public downloadCSV(params: WHHttpParams): Observable<Blob> {
    const httpParams: HttpParams = initHttpParams(params);
    return this.httpClient.get(`${environment.apiUrl + FLOW_GATEWAY}/csv`, {
      headers: { 'Content-Type': 'application/csv' },
      params: httpParams,
      responseType: 'blob',
    });
  }

  // BULK
  public downloadBulk(
    targetParent: string,
    targetID: string,
    bulkType: string,
    workObjectTypeId?: number,
  ): Observable<Blob> {
    switch (targetParent) {
      case 'SUBSIDIARY':
        return this.httpClient.get(
          `${environment.apiUrl + FLOW_GATEWAY}/${'subsidiaries'}/${bulkType}/files`,
          {
            headers: { 'Content-Type': 'application/zip' },
            responseType: 'blob',
          },
        );
      case 'WORK_OBJECT':
        const workObjectEndpoint: string =
          setWorkObjectTypeIdPath(workObjectTypeId);
        return this.httpClient.get(
          `${environment.apiUrl + FLOW_GATEWAY}/${workObjectEndpoint}/${targetID}/${bulkType}/files`,
          {
            headers: { 'Content-Type': 'application/zip' },
            responseType: 'blob',
          },
        );
      case 'WORK_ORDER':
        return this.httpClient.get(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workOrders'}/${targetID}/${bulkType}/files`,
          {
            headers: { 'Content-Type': 'application/zip' },
            responseType: 'blob',
          },
        );
      case 'WORK_STEP':
        return this.httpClient.get(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workSteps'}/${targetID}/${bulkType}/files`,
          {
            headers: { 'Content-Type': 'application/zip' },
            responseType: 'blob',
          },
        );
      default:
        return of(undefined);
    }
  }

  // COUNT ENTRY
  public getEntryCount(
    targetParent: string,
    targetID: string,
    params: WHHttpParams,
  ): Observable<number> {
    const httpParams: HttpParams = initHttpParams(params);
    switch (targetParent) {
      case 'SUBSIDIARY':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'subsidiaries'}/count/entries`,
          { params: httpParams },
        );
      case 'WORK_OBJECT':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workObjects'}/${targetID}/count/entries`,
          { params: httpParams },
        );
      case 'WORK_ORDER':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workOrders'}/${targetID}/count/entries`,
          { params: httpParams },
        );
      case 'WORK_STEP':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workSteps'}/${targetID}/count/entries`,
          { params: httpParams },
        );
      default:
        return of(undefined);
    }
  }

  // COUNT ENTRY FILE
  public getEntryFileCount(
    targetParent: string,
    targetID: string,
    params: WHHttpParams,
  ): Observable<number> {
    const httpParams: HttpParams = initHttpParams(params);
    switch (targetParent) {
      case 'SUBSIDIARY':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'subsidiaries'}/count/entryfiles`,
          { params: httpParams },
        );
      case 'WORK_OBJECT':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workObjects'}/${targetID}/count/entryfiles`,
          { params: httpParams },
        );
      case 'WORK_ORDER':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workOrders'}/${targetID}/count/entryfiles`,
          { params: httpParams },
        );
      case 'WORK_STEP':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workSteps'}/${targetID}/count/entryfiles`,
          { params: httpParams },
        );
      default:
        return of(undefined);
    }
  }

  // COUNT REPORT FILE
  public getReportFileCount(
    targetParent: string,
    targetID: string,
    params: WHHttpParams,
  ): Observable<number> {
    const httpParams: HttpParams = initHttpParams(params);
    switch (targetParent) {
      case 'SUBSIDIARY':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'subsidiaries'}/count/reportfiles`,
          { params: httpParams },
        );
      case 'WORK_OBJECT':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workObjects'}/${targetID}/count/reportfiles`,
          { params: httpParams },
        );
      case 'WORK_ORDER':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workOrders'}/${targetID}/count/reportfiles`,
          { params: httpParams },
        );
      case 'WORK_STEP':
        return this.httpClient.get<number>(
          `${environment.apiUrl + FLOW_GATEWAY}/${'workSteps'}/${targetID}/count/reportfiles`,
          { params: httpParams },
        );
      default:
        return of(undefined);
    }
  }

  // MATERIAL USAGE SUMMARY
  public getWorkObjectMaterialSummaryCSV(payload): Observable<Blob> {
    const httpParams: HttpParams = initHttpParams(payload);
    return this.httpClient.post<Blob>(
      `${environment.apiUrl + FLOW_GATEWAY}/workObjects/materialconsumptionreport/summary/csv`,
      payload,
      {
        params: httpParams,
        responseType: 'blob' as 'json',
      },
    );
  }

  // MATERIAL USAGE REPORT
  public getWorkObjectMaterialReportCSV(payload): Observable<Blob> {
    const httpParams: HttpParams = initHttpParams(payload);
    return this.httpClient.post<Blob>(
      `${environment.apiUrl + FLOW_GATEWAY}/workObjects/materialconsumptionreport/workobjects/csv`,
      payload,
      {
        params: httpParams,
        responseType: 'blob' as 'json',
      },
    );
  }
}
