<section class="contact-customer-mat-dialog">
  <div class="detail-card-header">
    <span whFlex class="w-h-header-icon-span">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="userIcon"
      ></i>
    </span>
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "workobject.ui.contactclient.header" | translate }}
      </div>
    </span>
  </div>
  <div class="contact-customer-mat-dialog-body">
    <div
      class="messages-container flex--r-c gap--8 info m-b--16"
      *ngIf="isContactsEmpty"
    >
      <mat-icon>info</mat-icon>
      {{ "workobject.ui.contactclient.nocontactswarning" | translate }}
    </div>
    <div class="flex--r-s-sb gap--16">
      <div class="contact-person-detail flex-2" *ngIf="!isContactsEmpty">
        <span whFlex="50">
          <div class="contact-person-wrapper">
            <div class="w-h-label html-overflow">
              {{ "workobject.model.contacts" | translate }}
            </div>
            <div class="contact-box">
              <div *ngFor="let contactDTO of contacts">
                <div class="contact-field mat-elevation-z1">
                  <span
                    whFlex="44px"
                    style="align-self: center; text-align: center"
                  >
                    <i
                      class="workheld-icons contact-icon"
                      [innerHTML]="contacPersonIcon"
                    ></i>
                  </span>
                  <span whFlex="calc(100% - 44px)">
                    <div style="line-height: 18px" class="html-overflow">
                      <span
                        style="font-weight: bold"
                        matTooltip="{{
                          contactDTO?.firstname
                            ? contactDTO?.firstname + ' ' + contactDTO?.lastname
                            : ''
                        }}"
                      >
                        {{
                          contactDTO?.firstname
                            ? contactDTO?.firstname + " " + contactDTO?.lastname
                            : "-"
                        }}
                      </span>
                    </div>
                    <div style="line-height: 18px" class="html-overflow">
                      <div class="flex--c-fs">
                        <a
                          *ngIf="
                            contactDTO?.countryCodeMobile &&
                            contactDTO?.mobilePhone
                          "
                          href="tel:{{
                            contactDTO?.countryCodeMobile +
                              contactDTO?.mobilePhone
                          }}"
                          >{{
                            contactDTO?.countryCodeMobile +
                              " " +
                              contactDTO?.mobilePhone
                          }}</a
                        >
                        <span
                          *ngIf="
                            !contactDTO?.countryCodeMobile ||
                            !contactDTO?.mobilePhone
                          "
                          >-</span
                        >
                        <a
                          *ngIf="
                            contactDTO?.countryCodeLandLine &&
                            contactDTO?.landLinePhone
                          "
                          href="tel:{{
                            contactDTO?.countryCodeLandLine +
                              contactDTO?.landLinePhone
                          }}"
                          >{{
                            contactDTO?.countryCodeLandLine +
                              " " +
                              contactDTO?.landLinePhone
                          }}</a
                        >
                        <span
                          *ngIf="
                            !contactDTO?.countryCodeLandLine ||
                            !contactDTO?.landLinePhone
                          "
                          >-</span
                        >
                      </div>
                    </div>
                    <div style="line-height: 18px" class="html-overflow">
                      <a
                        *ngIf="contactDTO?.mail"
                        href="mailto:{{ contactDTO?.mail }}"
                        >{{ contactDTO?.mail }}</a
                      >
                      <span *ngIf="!contactDTO?.mail">-</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div
        [ngClass]="{ 'flex-1': !isContactsEmpty }"
        [ngStyle]="{ minWidth: isContactsEmpty ? '300px' : 'auto' }"
      >
        <div class="w-h-label-no-margine html-overflow">
          {{ "workorder.model.notification" | translate }}
        </div>
        <w-h-remind-technician-notification-settings [bulkAction]="true" />
      </div>
    </div>

    <div style="margin-top: 15px; margin-bottom: 15px">
      <mat-divider class="wh-dialog-divider"></mat-divider>
    </div>
    <form [formGroup]="contactCustomerFormGroup">
      <div fxLayout fxLayoutAlign="left top" fxLayoutGap="20px">
        <div style="padding-top: 6px">
          <mat-form-field whFlex="90">
            <mat-label>{{
              "workobject.model.contactclient.reason" | translate
            }}</mat-label>
            <mat-select formControlName="enumReason" required>
              <mat-option
                *ngFor="let contactReason of ENUMS.CUSTOMER_CONTACT | whenum"
                [value]="contactReason?.id"
              >
                {{ contactReason?.translateKey | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="default-max-width">
        <lib-w-h-datetime-picker
          *ngIf="contactCustomerFormGroup.value.enumReason == 'YES'"
          [parentFormGroup]="tempFormGroup"
          startDateForm="dateSlotForm"
          startTimeForm="timeSlotForm"
          [hasEndDate]="false"
          [futureDate]="true"
          [hasTimer]="true"
          [startDateLabel]="'workobject.model.contactclient.timeslot'"
        ></lib-w-h-datetime-picker>
        <mat-error
          *ngIf="
            contactCustomerFormGroup.controls['timeSlot'].invalid ||
            isTimeSlotInvalid
          "
          >{{
            "workobject.model.contactclient.timeslot.error" | translate
          }}</mat-error
        >
      </div>
      <div>
        <mat-form-field whFlex>
          <mat-label>{{
            "workobject.model.contactclient.comment" | translate
          }}</mat-label>
          <textarea
            matInput
            placeholder="{{
              'workobject.model.contactclient.comment' | translate
            }}"
            formControlName="comment"
            [rows]="6"
          ></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="dialog-action-wrapper" mat-dialog-actions>
    <button
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="cancel()"
      [disabled]="saving"
    >
      <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
      {{ "app.cancel.label" | translate | uppercase }}
    </button>
    <button
      color="accent"
      class="dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      [disabled]="isFormGroupInvalid || saving"
      (click)="createLogAndUpdateWorkObject()"
    >
      <mat-spinner style="display: inline-block" *ngIf="saving" [diameter]="18">
      </mat-spinner>
      <i class="workheld-icons" [innerHTML]="saveIcon" *ngIf="!saving"></i>
      {{ "app.save.label" | translate | uppercase }}
    </button>
  </div>
</section>
