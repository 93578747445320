import { Component, input, output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { WHSafeTranslateService } from '@workheld/workheld-shared-lib';

export interface WHChipMenuItem {
  translateKey: string;
  actionKey: string;
  disableByObjectKey?: string;
  disableById?: string;
}

@Component({
  selector: 'app-w-h-chip-list',
  templateUrl: './w-h-chip-list.component.html',
  styleUrls: ['./w-h-chip-list.component.scss'],
})
export class WHChipListComponent {
  data = input([], {
    transform: (value: any) => {
      for (const item of value) {
        for (const key of Object.keys(item)) {
          if (typeof item[key] === 'string') {
            item[key] = this.safeTranslateService.instant(item[key]);
          }
        }
      }
      return value;
    },
  });

  navigateToKey = input('');
  imageKey = input();
  placeholderImg = input();
  titleKey = input();
  badgeKey = input('');
  isDisabled = input(false);
  maxHeight = input('100%');
  isRemoveButtonVisible = input(false);
  // Give a translation key and the actionkey should be implemented in the parent component
  menuItems = input([] as WHChipMenuItem[]);
  tooltipKeys = input([] as string[]);
  icon = input('');
  matIcon = input('');
  isStacked = input(true);
  isLoading = input(false);
  showScrollBackground = input(false);
  removingChip = signal(null);
  suffixIcon = input('');

  // Emit the item to be removed
  handleRemoveChip = output<any>();
  // The call back from triggering the menu item from input menuItems
  handleMenuTrigger = output<any>();

  constructor(
    private router: Router,
    private safeTranslateService: WHSafeTranslateService,
  ) {}

  getTooltip(item: any) {
    let tooltip = '';
    if (!this.tooltipKeys() || !this.tooltipKeys().length) return '';
    this.tooltipKeys().forEach((key) => {
      if (key?.length && key in item) {
        tooltip += `${item[key]} `;
      }
    });

    return tooltip;
  }

  getBadge(item: any) {
    return this.badgeKey() ? item[this.badgeKey()] : null;
  }

  handleNavigate(item: any) {
    if (this.navigateToKey()) this.router.navigate(item[this.navigateToKey()]);
  }

  onRemoveChip(event, item) {
    event.stopPropagation();
    this.removingChip.set(item);
    this.handleRemoveChip.emit(item);
  }

  onMenuItemClick(payload, actionKey) {
    // This should be implemented in the parent component
    // Switch case on actionKey
    this.handleMenuTrigger.emit({ actionKey, payload });
  }
}
