// INDEX APP SERVICES

// ANGULAR
import { ErrorHandler } from '@angular/core';

import {
  // CORE
  WHBreadcrumbService,
  WHDomEventService,
  WHErrorHandlerService,
  WHLayoutConfigService,
  WHNgxToastrService,
  WHNgxLoggerService,
  WHMatSnackbarService,
  WHScrollbarService,
  WHThemeConfigService,
  WHWebsocketService,
  DateTimeService,
  WHSafeTranslateService,

  // API
  WHDataService,
  WHDepartmentDataService,
  WHFileDataService,
  WHFeatureDataService,
  WHFeatureConfigDataService,
  WHI18nDataService,
  WHLoginDataService,
  WHMaterialDataService,
  WHSkillsDataService,
  WHMetadataDataService,
  WHSearchDataService,
  WHTenantDataService,
  WHUserDataService,

  // FLOW,
  WHBomNodeDataService,
  WHChecklistDefinitionDataService,
  WHChecklistInstanceDataService,
  WHCheckInstanceFileDataService,
  WHCustomerDataService,
  WHEntryDataService,
  WHEntryFileDataService,
  WHEquipmentDataService,
  WHEventLogDataService,
  WHFlexPropertyDefinitionDataService,
  WHReportFileDataService,
  WHToolDataService,
  WHWorkerDataService,
  WHWorkObjectDataService,
  WHWorkObjectTemplateDataService,
  WHWorkOrderDataService,
  WHWorkOrderFileDataService,
  WHWorkStepDataService,

  // CALL
  WHConnectDataService,
  WHContactDataService,
  WHChatDataService,
} from '@workheld/workheld-shared-lib';
import { SERVICES_ASYNC } from './app-services-async/index.async.services';
import { ErrorHandlerService } from './app-services-async/w-h-error-handler.service';
import { WHCustomMonitoringService } from './app-services-async/w-h-logging.service';

export const SERVICES_CORE: any[] = [
  WHBreadcrumbService,
  WHDomEventService,
  WHLayoutConfigService,
  WHSafeTranslateService,
  WHNgxToastrService,
  WHNgxLoggerService,
  WHMatSnackbarService,
  WHScrollbarService,
  WHThemeConfigService,
  WHWebsocketService,
];

export const SERVICES_DATA: any[] = [
  WHMetadataDataService,
  DateTimeService,
  WHTenantDataService,
  WHUserDataService,
  WHDataService,
  WHChecklistDefinitionDataService,
  WHDepartmentDataService,
  WHI18nDataService,
  WHFeatureDataService,
  WHFeatureConfigDataService,
  WHLoginDataService,
  WHMaterialDataService,
  WHSkillsDataService,
  WHFileDataService,
  WHSearchDataService,
  // FLOW
  WHBomNodeDataService,
  WHChecklistInstanceDataService,
  WHCheckInstanceFileDataService,
  WHCustomerDataService,
  WHEntryDataService,
  WHEntryFileDataService,
  WHEquipmentDataService,
  WHEventLogDataService,
  WHFlexPropertyDefinitionDataService,
  WHWorkObjectDataService,
  WHReportFileDataService,
  WHToolDataService,
  WHWorkObjectTemplateDataService,
  WHWorkOrderDataService,
  WHWorkOrderFileDataService,
  WHWorkStepDataService,
  WHWorkerDataService,

  // CALL
  WHConnectDataService,
  WHContactDataService,
  WHChatDataService,
].concat(SERVICES_ASYNC);
