// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import {
  FLOW_GATEWAY,
  IWHWorkObjectEndpointConfigModel,
  WHWorkObjectDataService,
} from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkObjectCrudStoreActionTypes,
  WHWorkObjectCrudStoreActions,
  GetWHWorkObjectDTO,
  SetWHWorkObjectDOM,
  UpdateWHWorkObjectDOM,
} from './w-h-work-object-crud-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { WHWorkObjectDOM, IWHWorkObjectDTO } from '@workheld/workheld-shared-lib';
import { environment } from 'src/environments/environment';

@Injectable()
export class WHWorkObjectCrudStoreEffects {
  private workObjectTypeId: number = 0;

  getWHWorkObjectDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHWorkObjectCrudStoreActionTypes.GetWHWorkObjectDTO),
      switchMap((actions: GetWHWorkObjectDTO) => {
        const endpointConfig: IWHWorkObjectEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          workObjectTypeId: actions.payload.workObjectTypeId,
          workObjectId: actions.payload.workObjectId,
        };
        this.workObjectTypeId = actions.payload.workObjectTypeId;
        return this.workObjectDataService.getWorkObjectByWorkObjectType(
          endpointConfig
        );
      }),
      mergeMap((workObjectDTO: IWHWorkObjectDTO) => {
        const workObjectDOM: WHWorkObjectDOM | any =
          this.dataModelHelperService.initWorkObjectTypeDOM(workObjectDTO);
        return [
          new SetWHWorkObjectDOM({
            workObjectTypeId: this.workObjectTypeId,
            workObjectDOM,
          }),
        ];
      }),
    );
  });

  updateWHWorkObjectDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHWorkObjectCrudStoreActionTypes.UpdateWHWorkObjectDOM),
      switchMap((actions: UpdateWHWorkObjectDOM) => {
        const workObjectDOM: WHWorkObjectDOM | any =
        this.dataModelHelperService.initWorkObjectTypeDOM(actions.payload.workObject);
        return [
          new SetWHWorkObjectDOM({
            workObjectTypeId: actions.payload.workObjectTypeId,
            workObjectDOM,
          }),
        ];
      }),
    );
  });

  constructor(
    private actions$: Actions<WHWorkObjectCrudStoreActions>,
    private workObjectDataService: WHWorkObjectDataService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
