<div class="w-h-material-preview">
  <div class="material-data-table-container flex--r-e-sb">
    <div class="flex--r-c gap--16 m-t--16 flex-1">
      <w-h-autocomplete-scroll
        style="width: 40%"
        data-cy="material-catalog-dropdown"
        [data$]="materialList$"
        [loading$]="materialDropdownLoading$"
        [labelKey]="'materiallist.ui.assignment.header'"
        [itemString]="'title'"
        [subItemStrings]="['extId', 'inventoryTypeKey']"
        [fallbackImage]="'MATERIAL'"
        [imageUrlKey]="'pictureURL'"
        [disableAutoComplete]="!workstep?.isActive"
        [clearSelected]="disableAddNewMaterial"
        (onScrollEvent)="handleScrollEvent($event)"
        (onItemSelect)="handleMaterialSelect($event)"
      ></w-h-autocomplete-scroll>
      <button
        data-cy="assign-material-button"
        (click)="assignMaterialToWS()"
        mat-mini-fab
        [disabled]="disableAddNewMaterial"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div
      *ngIf="isReserveFlagEnabled && isReservationMade"
      class="reservation-wrapper"
    >
      <div class="reservation-ticket">
        <div class="flex--c-c-l gap--8" style="height: 100%">
          <div *ngIf="reservationStatus">
            <div
              class="wh-status"
              matTooltip="{{ 'app.ui.reservationStatus.label' | translate }}"
              [ngClass]="colorTags[reservationStatus]"
            >
              {{ RESERVATION_STATUS[reservationStatus] | translate }}
            </div>
          </div>
          <span><b>Reservation number</b> {{ reservationNumber || "-" }}</span>
          <div class="flex--row gap--4">
            <span><b>Reservation date:</b></span>
            @if (workstep?.isDateValid) {
              <span class="flex--row gap--8"
                >{{
                  workstep?.startDate | date: "shortDate" : "" : currentLocale
                }}
                <mat-icon style="opacity: 0.6">trending_flat</mat-icon>
                {{
                  workstep?.endDate | date: "shortDate" : "" : currentLocale
                }}</span
              >
            } @else {
              -
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-h-data-table-container">
    @if (materialsTableLoading$ | async) {
      <div class="loading-materials">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    } @else if (
      (materialsTableLoading$ | async) === false &&
      dataSource?.data?.length === 0
    ) {
      <div class="data-table-loading-shade">
        {{ "app.nodata.label" | translate }}
      </div>
    }
    <div style="height: 100%; min-height: 401px" class="flex--c-fs-sb">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="itemPositionNumber">
          <mat-header-cell class="flex-1" *matHeaderCellDef>
            <mat-icon>tag</mat-icon>
          </mat-header-cell>
          <mat-cell class="flex-1" *matCellDef="let element">
            @if (!element.itemPositionNumber) {
              -
            } @else {
              {{ element.itemPositionNumber }}
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="materialTitle">
          <mat-header-cell class="flex-2" mat-sort-header *matHeaderCellDef>{{
            "material.model.name" | translate
          }}</mat-header-cell>
          <mat-cell class="flex-2" *matCellDef="let element">
            <div class="flex--col flex-1">
              <span class="html-overflow" style="display: block">
                {{ element.materialTitle }}
              </span>
              <small class="html-overflow" style="display: block">
                {{ element.materialExtId }}
              </small>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unit">
          <mat-header-cell class="flex-1" mat-sort-header *matHeaderCellDef>{{
            "material.model.unit" | translate
          }}</mat-header-cell>
          <mat-cell class="flex-1" *matCellDef="let element">
            @if (element?.materialUnit) {
              <app-w-h-chip-list
                style="width: 100%"
                [data]="[{ materialUnit: UNIT[element?.materialUnit] }]"
                [tooltipKeys]="['materialUnit']"
                [isStacked]="false"
                [titleKey]="'materialUnit'"
              ></app-w-h-chip-list>
            } @else {
              -
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="inventoryType">
          <mat-header-cell class="flex-2" mat-sort-header *matHeaderCellDef>
            {{ "app.ui.inventoryType.label" | translate }}
          </mat-header-cell>
          <mat-cell class="flex-2" *matCellDef="let element">
            @if (element.inventoryType) {
              <app-w-h-chip-list
                style="width: 100%"
                [data]="[
                  {
                    inventoryType:
                      INVENTORY_TYPE_TRANSLATIONS[element?.inventoryType],
                  },
                ]"
                [tooltipKeys]="['inventoryType']"
                [matIcon]="'inventory_2'"
                [isStacked]="false"
                [titleKey]="'inventoryType'"
              ></app-w-h-chip-list>
            } @else {
              -
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef>
            {{ "app.quantity.label" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field>
              <input
                matInput
                type="number"
                min="1"
                [(ngModel)]="element.quantity"
                (ngModelChange)="onInputChange(element)"
                [disabled]="
                  element.deleted || element?.materialStatus === 'INACTIVE'
                "
                autocomplete="off"
              />
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="additionalInformation">
          <mat-header-cell class="flex-2" *matHeaderCellDef>{{
            "app.ui.additionalInfo.label" | translate
          }}</mat-header-cell>
          <mat-cell class="flex-2" *matCellDef="let element">
            <!-- <mat-icon (click)="openPopup($event)">add_comment</mat-icon> -->
            <mat-form-field
              matTooltip="{{ element.additionalInformation }}"
              style="width: 100%"
            >
              <input
                matInput
                [(ngModel)]="element.additionalInformation"
                [disabled]="
                  element.deleted || element?.materialStatus === 'INACTIVE'
                "
                (change)="onInputChange(element)"
              />
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="availability">
          <mat-header-cell class="flex-2" *matHeaderCellDef>{{
            "app.ui.availabilityStatus.label" | translate
          }}</mat-header-cell>
          <mat-cell class="flex-2" *matCellDef="let element">
            @if (element.inventoryType !== "NON_INVENTORY_MANAGED") {
              {{
                (element.isAvailable === null
                  ? "app.notCalculated.label"
                  : element.isAvailable
                    ? "app.available.label"
                    : "app.notAvailable.label"
                ) | translate
              }}
            } @else {
              -
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reservationItemStatus">
          <mat-header-cell class="flex-2" *matHeaderCellDef>{{
            "app.ui.reservationStatus.label" | translate
          }}</mat-header-cell>
          <mat-cell class="flex-2" *matCellDef="let element">
            @if (element.reservationItemStatus) {
              <app-w-h-chip-list
                style="width: 100%"
                [data]="[
                  {
                    reservationItemStatus:
                      RESERVATION_ITEM_STATUS[element.reservationItemStatus],
                  },
                ]"
                [tooltipKeys]="['reservationItemStatus']"
                [matIcon]="'event_seat'"
                [isStacked]="false"
                [titleKey]="'reservationItemStatus'"
              ></app-w-h-chip-list>
            } @else if (element?.inventoryType) {
              <app-w-h-chip-list
                style="width: 100%"
                [data]="[
                  {
                    inventoryType: INVENTORY_TYPE[element?.inventoryType],
                  },
                ]"
                [matIcon]="'event_seat'"
                [isStacked]="false"
                [titleKey]="'inventoryType'"
              ></app-w-h-chip-list>
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="materialStatus">
          <mat-header-cell *matHeaderCellDef class="flex-1"></mat-header-cell>
          <mat-cell class="flex--r-c-r flex-1" *matCellDef="let element">
            @if (element?.materialStatus) {
              {{
                "material.enum.status." + element?.materialStatus | translate
              }}
            } @else {
              -
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="flex--r-c-r" *matCellDef="let element">
            <button
              [disabled]="element.deleted"
              mat-icon-button
              data-cy="delete-assignment-button"
              (click)="handleDelete(element)"
            >
              @if (isInRemoveAssignments(element) && !element.deleted) {
                <mat-icon data-cy="undo-button">undo</mat-icon>
              } @else {
                <mat-icon>delete_forever</mat-icon>
              }
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row
          class="p-l--8"
          *matHeaderRowDef="displayedColumns"
        ></mat-header-row>
        <mat-row
          class="p-l--8"
          [ngClass]="{
            'disabled-row': row?.deleted || row.materialStatus === 'INACTIVE',
          }"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
      </mat-table>

      <mat-paginator
        style="width: 100%"
        class="w-h-paginator"
        [length]="dataSource?.data?.length"
        [pageSize]="5"
      >
      </mat-paginator>
    </div>
  </div>
  <div class="flex--r-c-fe flex-1 gap--12 p--16">
    @if (!workstep?.isDateValid && isReserveFlagEnabled) {
      <div class="messages-container info">
        <div class="flex--r-c gap--8">
          <mat-icon>info</mat-icon>
          <span>{{ "app.workstepInFuture.info" | translate }}</span>
        </div>
      </div>
    }
    <button
      type="button"
      (click)="handleClose()"
      mat-flat-button
      class="material-crud-button mat-elevation-z2"
    >
      {{ "app.cancel.label" | translate | uppercase }}
    </button>
    <button
      data-cy="save-assign-button"
      (click)="onSubmit('SAVE')"
      type="button"
      mat-flat-button
      [disabled]="!isSaveButtonEnabled()"
      color="accent"
      class="material-crud-button mat-elevation-z2"
    >
      {{ "app.save.label" | translate | uppercase }}
    </button>
    <div
      [matTooltip]="
        !workstep.isDateValid ? ('app.ui.setDates.error' | translate) : ''
      "
    >
      <button
        *ngIf="isReserveFlagEnabled"
        (click)="onSubmit('RESERVATION')"
        type="button"
        mat-flat-button
        [disabled]="!workstep.isDateValid || !isReserveButtonEnabled()"
        color="accent"
        class="material-crud-button mat-elevation-z2"
      >
        {{ "app.reserve.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
