import {
  FLOW_GATEWAY,
  IWHBomNodeDTO,
  updateObject,
  WHBomNodeDataService,
  WHMetadataDataService,
  WHDataService,
  WHEntryDOM,
  IWHDefectEntryDTO,
  WHDefectEntryDOM,
  IWHEntryDTO,
  WHNgxToastrService,
  WHNgxToastrENUM,
  WHFeatureKeyENUM,
  WHLoginDataService,
  WHTranslationTypeENUM,
} from '@workheld/workheld-shared-lib';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  signal,
  effect,
} from '@angular/core';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { catchError, first, Subscription, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { WHNestedBomNodeDOM } from 'src/app/w-h-module/w-h-bom-node-crud-form/w-h-nested-node.model';
import { MaterialDTO } from 'src/app/app-services-async/material-async.service';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EntryAsyncService } from 'src/app/app-services-async/entry-async.service';
import { UIService } from 'src/app/app-services/ui.service';
import { WHEnumDataService } from 'src/app/app-services-helper/w-h-enum-helper.service';

@UntilDestroy()
@Component({
  selector: 'ng-bee-type4-defect-entry-static-data',
  templateUrl: './type4-defect-entry-static-data.component.html',
  styleUrls: ['./type4-defect-entry-static-data.component.scss'],
})
export class Type4DefectEntryStaticDataComponent implements OnInit, OnDestroy {
  public materialDTO: MaterialDTO;
  entryFormGroup = new FormGroup({
    title: new FormControl(''),
    resolved: new FormControl(false),
    errorCode: new FormControl(''),
  });
  isEditable = signal(false);
  isEditMode = signal({ title: false, resolved: false, errorCode: false });
  $ENUMS_OBJECT = signal(null);
  ENUMS = WHTranslationTypeENUM;

  // DATA VAR
  @Input() public entryDOM: WHEntryDOM & {
    workObjectStatusId?: number;
    maintenanceNotificationStatusId: number;
    maintenanceNotificationNumberSap?: number;
  };
  public defectEntryDOM: WHDefectEntryDOM = {
    loading: true,
    loaded: false,
  } as any;

  // BomNode
  bomnode: WHNestedBomNodeDOM;
  bomNodeName: string;
  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];
  isEditFeatureEnabled: boolean;
  currentLocale: string;
  colorTags = ['orange', 'blue', 'green', 'black'];

  constructor(
    private metadataDataService: WHMetadataDataService,
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private bomNodeDataService: WHBomNodeDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private entryService: EntryAsyncService,
    private ngxToastrService: WHNgxToastrService,
    private loginDataService: WHLoginDataService,
    private uiService: UIService,
    private whEnumDataService: WHEnumDataService,
  ) {
    effect(() => {
      this.currentLocale = uiService.$currentLocale();
    });
  }

  ngOnInit() {
    this.$ENUMS_OBJECT = this.whEnumDataService.$ENUMS_OBJECT;

    this.getDefectEntry();

    this.subscriptions.push(
      this.loginDataService.featureConfigMap$.subscribe(
        (featureConfigMap: Map<string, boolean>) => {
          this.isEditFeatureEnabled = featureConfigMap.get(
            WHFeatureKeyENUM.EDIT_DEFECT_ENTRY,
          );
          this._checkIfEntryIsEditable();
        },
      ),
    );
  }

  getDefectEntry() {
    this.subscriptions.push(
      this.dataService
        .getByUrl(this.entryDOM.entryDetailURL)
        .subscribe((defectEntryDTO: IWHDefectEntryDTO) => {
          this.defectEntryDOM = updateObject(
            this.defectEntryDOM,
            this.dataModelHelperService.initDefectEntryDOM(defectEntryDTO),
          );

          this.fetchMaterialConsumptionEntry(defectEntryDTO?.materialDetailURL);

          if (this.defectEntryDOM?.bomNodeId)
            this.assignBomNode(this.defectEntryDOM.bomNodeId);

          this._initForm();

          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  toggleEditMode(formControl: string) {
    this.isEditMode.update((value) => {
      return {
        ...value,
        [formControl]: !value[formControl],
      };
    });
    this._initForm(formControl);
  }

  private _initForm(formControl = null) {
    if (formControl) {
      this.entryFormGroup.patchValue({
        [formControl]: this.defectEntryDOM[formControl],
      });
      return;
    }

    this.entryFormGroup.patchValue({
      title: this.defectEntryDOM.title,
      resolved: this.defectEntryDOM.resolved,
      errorCode: this.defectEntryDOM.errorCode,
    });
  }

  private fetchMaterialConsumptionEntry(detailURL: string) {
    if (!detailURL) return;

    this.subscriptions.push(
      this.dataService.getByUrl(detailURL).subscribe((materialDTO: any) => {
        this.materialDTO = materialDTO;
        this.dataModelHelperService.materialInventoryType.set(
          materialDTO?.inventoryType,
        );
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public handleSubmit(formControl: string): void {
    this.subscriptions.push(
      this.entryService
        .updateDefectEntryByEntryID(this.entryDOM.id, {
          [formControl]: this.entryFormGroup.controls[formControl].value,
        })
        .pipe(
          catchError((error) => {
            return throwError(error);
          }),
        )
        .subscribe((entryDTO: IWHEntryDTO) => {
          this.getDefectEntry();
          this.toggleEditMode(formControl);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: 'entry.ui.updatesuccess.notification',
          });
        }),
    );
  }

  private _checkIfEntryIsEditable(): void {
    if (!this.isEditFeatureEnabled) {
      this.isEditable.set(false);
      return;
    }

    if (
      this.entryDOM?.entryStatusId === 1 &&
      (this.entryDOM.workObjectStatusId === 0 ||
        this.entryDOM.workObjectStatusId === 1 ||
        this.entryDOM.workObjectStatusId === 2)
    ) {
      this.isEditable.set(true);
      return;
    }

    if (
      this.entryDOM?.entryStatusId === 3 &&
      this.entryDOM.workObjectStatusId &&
      this.entryDOM.workObjectStatusId === 2
    ) {
      this.isEditable.set(true);
      return;
    }

    this.isEditable.set(false);
  }

  assignBomNode(bomNodeId: string) {
    this.bomNodeDataService
      .getBomNodeDTO({
        apiUrl: environment.apiUrl + FLOW_GATEWAY,
        bomNodeId: bomNodeId,
      })
      .pipe(first())
      .subscribe((bomNode: IWHBomNodeDTO) => {
        this.bomnode = new WHNestedBomNodeDOM(bomNode);
        this.bomNodeName = bomNode?.name;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
