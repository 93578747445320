<mat-form-field
  class="form-field"
  [ngClass]="{ 'disable-block': disableAutoComplete }"
>
  <mat-label>{{ labelKey | translate }}</mat-label>
  <input
    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
    type="text"
    #searchInput
    [placeholder]="inputPlaceholder | translate"
    [required]="required"
    matInput
    data-cy="autocomplete-input"
    [formControl]="searchCtrl"
    [matAutocomplete]="auto"
    #trigger="matAutocompleteTrigger"
    appAutocompletePosition="trigger"
  />

  <mat-icon
    matSuffix
    *ngIf="(loading$ | async) === false && searchCtrl.value && !isMultiselect"
    (click)="clear($event)"
    >close
  </mat-icon>
  <mat-icon
    matSuffix
    *ngIf="
      ((loading$ | async) === false && !searchCtrl.value) ||
      ((loading$ | async) === false && isMultiselect)
    "
    (click)="clear()"
    >arrow_drop_down
  </mat-icon>
  <mat-spinner diameter="15" matSuffix *ngIf="loading$ | async"></mat-spinner>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionsScroll)="onScrollDown()"
    (optionSelected)="setItem($event)"
    (openedChange)="onOpenStateChange($event)"
    (closed)="onClose()"
  >
    <mat-option
      *ngIf="(loading$ | async) === false && itemDomList.length === 0"
      class="flex--r-c-c"
      >({{ "app.nodata.label" | translate }})</mat-option
    >
    @if (itemDomList?.length > 0) {
      @if (isMultiselect) {
        <mat-option
          class="w-h-dropdown-option"
          *ngFor="let item of itemDomList"
          [value]="item"
          [matTooltipDisabled]="
            itemString ? item[itemString]?.length < 40 : item.name?.length < 40
          "
          [matTooltip]="itemString ? item[itemString] : item.name"
          [disabled]="item?.disabled"
          [matTooltipPosition]="'left'"
          (click)="optionClicked($event, item)"
        >
          <mat-checkbox
            [checked]="item.selected"
            (change)="toggleSelection(item)"
            (click)="$event.stopPropagation()"
            class="flex--r-c-c gap--12 p-l--4 p-t--4 p-b--4"
            style="min-width: 0"
          >
            @if (imageUrlKey) {
              <img
                class="mat-elevation-z2"
                alt="img"
                *ngIf="
                  item[imageUrlKey]
                    | WHImgAuthHeader: fallbackImagePath
                    | async as image
                "
                [src]="image"
              />
            }
            <div class="flex--col" style="min-width: 0">
              <span>
                {{ item[itemString] || item?.name }}
              </span>
              <div class="sub-items" *ngIf="subItemStrings.length > 0">
                @for (subItemString of subItemStrings; track subItemString) {
                  @if (item[subItemString]) {
                    <span>
                      {{ item[subItemString] | translate }}
                    </span>
                  }
                }
              </div>
            </div>
          </mat-checkbox>
        </mat-option>
      } @else {
        <mat-option
          class="w-h-dropdown-option"
          *ngFor="let item of itemDomList"
          [value]="item"
          [matTooltipDisabled]="
            itemString ? item[itemString]?.length < 40 : item.name?.length < 40
          "
          [matTooltip]="itemString ? item[itemString] : item.name"
          [disabled]="item?.disabled"
          [matTooltipPosition]="'left'"
        >
          <div
            class="flex--r-c-c gap--12 p-l--4 p-t--4 p-b--4"
            style="min-width: 0"
          >
            @if (imageUrlKey) {
              <img
                class="mat-elevation-z2"
                alt="img"
                *ngIf="
                  item[imageUrlKey]
                    | WHImgAuthHeader: fallbackImagePath
                    | async as image
                "
                [src]="image"
              />
            }
            <div class="flex--col gap--4" style="min-width: 0">
              <span>
                {{ item[itemString] || item?.name }}
              </span>
              <div class="sub-items" *ngIf="subItemStrings.length > 0">
                @for (subItemString of subItemStrings; track subItemString) {
                  <span>
                    {{ item[subItemString] }}
                  </span>
                }
              </div>
            </div>
          </div>
        </mat-option>
      }
    }
  </mat-autocomplete>
</mat-form-field>
