import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { UIService } from 'src/app/app-services/ui.service';

@Pipe({
  name: 'translate',
  pure: false,
})
export class CustomTranslatePipePipe
  extends TranslatePipe
  implements PipeTransform
{
  translateService: TranslateService = inject(TranslateService);
  uiService = inject(UIService);

  transform(key: string, ...args: unknown[]): unknown {
    let result = super.transform(key, args);

    // If the translation is not found in current language,
    // then it tries to find it in the en.json file or else return key
    if (result === '' && key) {
      try {
        return this.uiService.enTranslations?.en[key]
          ? this.uiService.enTranslations?.en[key]
          : key;
      } catch (error) {
        return key;
      }
    }
    return result;
  }
}
