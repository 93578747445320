// ANGULAR
import { Component, OnInit, Inject } from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WHSafeTranslateService } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-mat-dialog-confirm-entry',
  templateUrl: './mat-dialog-confirm-entry.component.html',
  styleUrls: ['./mat-dialog-confirm-entry.component.scss'],
})
export class MatDialogConfirmEntryComponent implements OnInit {
  header: string;
  body = null;
  constructor(
    public matDialogRef: MatDialogRef<MatDialogConfirmEntryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerTranslateKey: string;
      headerParams?: Record<string, any>;
      bodyTranslateKey: string;
      bodyParams?: Record<string, any>;
      alertType: '' | 'info' | 'warn';
      alertTranslateKey?: string;
      submitActionTranslateKey?: string;
      isCancelHidden: boolean;
    },
    private safeTranslateService: WHSafeTranslateService
  ) {}

  ngOnInit() {
    if (this.data.bodyTranslateKey) {
      this.body = this.safeTranslateService.instant(
        this.data.bodyTranslateKey,
        this.data.bodyParams || undefined,
      );
    }
  }
}
