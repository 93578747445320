// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, WritableSignal, signal } from '@angular/core';

// RxJS 6
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';

// MODELS
import {
  IWHMaterialDTO,
  IWHMaterialPageResultDTO,
  IWHDTColumnModel,
  FLOW_GATEWAY,
  initHttpParams,
  MATERIAL_GATEWAY,
  noWhitespaceValidator,
} from '@workheld/workheld-shared-lib';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Pageable } from '@workheld/workheld-shared-lib/lib/models/w-h-pageable.model';

export const INVENTORY_TYPE = {
  INVENTORY_MANAGED: 'app.ui.inventoryManaged',
  NON_INVENTORY_MANAGED: 'app.ui.nonInventoryManaged',
};

export class MaterialDTO {
  workStep2MaterialId: string;
  reservationItemId?: string;
  itemPositionNumber: number;
  materialExtId: string;
  materialStatus: string;
  materialId: string;
  materialTitle: string;
  materialUnit: string;
  inventoryType: string;
  quantity: number;
  reservationItemStatus: string;
  additionalInformation: string;
  deleted: boolean;
}

export class WorkstepMaterialList {
  reservationId: string;
  reservationStatus: string;
  reservationListDetails: MaterialDTO[];
  workStepId: string;
  reservationTargetDate: string;
  reservationNumber: string;
}

export class MaterialAssignment {
  workStep2MaterialId?: string;
  reservationItemId?: string;
  id?: string;
  workStepId: string;
  materialId: string;
  quantity: number;
  additionalInformation?: string;
  materialAssignmentId?: string;
  additionalInfo?: string;
  deleted?: boolean;
}

export class MaterialsToWorkStepPayload {
  workStepId: string;
  assignments: MaterialAssignment[];
  removedAssignments: string[];
}

export class MaterialsReservationPayload {
  id?: string;
  workStepId: string;
  reservationTargetDate: string;
  workStepScheduledStartDate: string;
  reservationItems: MaterialAssignment[];
}

@Injectable()
export class MaterialAsyncService {
  workStepMaterialListEntity = {
    data$: new BehaviorSubject<WorkstepMaterialList>(null),
    loading$: new BehaviorSubject<boolean>(false),
  };
  materialDTO: WritableSignal<MaterialDTO> = signal(null);
  materialPageResultDTO: WritableSignal<IWHPageableMaterialDTO> = signal(null);

  fetchingMaterials: WritableSignal<boolean> = signal<boolean>(false);
  materialsLoaded: WritableSignal<boolean> = signal<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  public initDataTableColumn(): IWHDTColumnModel[] {
    return [
      { property: 'id', visible: true, headerLabelI18nKey: '' },
      {
        property: 'materialNumber',
        visible: true,
        headerLabelI18nKey: 'material.model.materialnumber',
      },
      {
        property: 'name',
        visible: true,
        headerLabelI18nKey: 'material.model.name',
      },
      {
        property: 'materialUnit',
        visible: true,
        headerLabelI18nKey: 'material.model.unit',
      },
      {
        property: 'materialTypeId',
        visible: true,
        headerLabelI18nKey: 'material.model.type',
      },
      {
        property: 'status',
        visible: true,
        headerLabelI18nKey: 'material.model.status',
      },
      {
        property: 'quantity',
        visible: true,
        headerLabelI18nKey: 'app.quantity.label',
      },
    ];
  }

  /**
   * Initializes the form group for the query parameters.
   * @returns The form group for the query parameters.
   */
  public initParamsFormGroup(page: number, searchTerm: string) {
    return new FormBuilder().group({
      page: [page],
      sort: ['id,asc'],
      searchTerm: [searchTerm],
      pageSize: [20],
      showInactive: [false],
    });
  }

  public initMaterialFormGroup(): FormGroup {
    return new FormBuilder().group({
      id: [null],
      title: [null, [noWhitespaceValidator]],
      description: [null],
      extId: [null, [noWhitespaceValidator]],
      unit: [null],
      status: [null],
      inventoryType: [null],
      serialNumberRequired: [false],
    });
  }

  public getMaterials(parms: any) {
    const httpParams: HttpParams = initHttpParams(parms);
    this.fetchingMaterials.set(true);

    this.getMaterialList(httpParams).subscribe(
      (materialPageResultDTO: IWHPageableMaterialDTO) => {
        this.materialPageResultDTO.set(materialPageResultDTO);
        this.fetchingMaterials.set(false);
        this.materialsLoaded.set(true);
      },
    );
  }

  public getMaterial(materialId: string) {
    this.fetchingMaterials.set(true);

    this.getMaterialDtoByMaterialId2(materialId).subscribe(
      (materialDTO: MaterialDTO) => {
        console.log('materialDTO', materialDTO);
        this.materialDTO.set(materialDTO);
        this.fetchingMaterials.set(false);
      },
    );
  }

  public updateMaterial(materialDTO: MaterialDTO): Observable<MaterialDTO> {
    return this.httpClient.put<MaterialDTO>(
      `${environment.apiUrl + MATERIAL_GATEWAY + '/v1'}/material/${
        materialDTO.id
      }`,
      materialDTO,
    );
  }

  public createMaterial(materialDTO: MaterialDTO): Observable<MaterialDTO> {
    return this.httpClient.post<MaterialDTO>(
      `${environment.apiUrl + MATERIAL_GATEWAY + '/v1'}/material`,
      materialDTO,
    );
  }

  public removeImageFile(materialId: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${
        environment.apiUrl + MATERIAL_GATEWAY + '/v1'
      }/material/${materialId}/materialPicture`,
    );
  }

  public createMaterialByMaterialDTO(
    materialDTO: IWHMaterialDTO,
  ): Observable<IWHMaterialDTO> {
    return this.httpClient.post<IWHMaterialDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/materials`,
      materialDTO,
    );
  }

  public updateMaterialByMaterialDTO(
    materialId: string,
    materialDTO: IWHMaterialDTO,
  ): Observable<IWHMaterialDTO> {
    return this.httpClient.post<IWHMaterialDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/materials/${materialId}`,
      materialDTO,
    );
  }

  public postMaterialImageFile(
    equipmentID: string,
    fileBlob: Blob,
  ): Observable<MaterialDTO> {
    const formData: FormData = new FormData();
    let mimeType = 'undefined';
    let extension = '.png';
    if (fileBlob.type) {
      mimeType = fileBlob.type;
      extension = '.' + mimeType.split('/')[1];
    }
    formData.append('file', fileBlob, 'avatar' + extension);

    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', mimeType);

    console.log('test', formData);
    return this.httpClient.post<MaterialDTO>(
      `${
        environment.apiUrl + MATERIAL_GATEWAY + '/v1'
      }/material/${equipmentID}/files`,
      formData,
      { headers },
    );
  }

  public getMaterialDtoByMaterialId(
    materialId: string,
  ): Observable<IWHMaterialDTO> {
    return this.httpClient.get<IWHMaterialDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/materials/${materialId}`,
    );
  }

  public getMaterialPreview(
    httpParams: HttpParams,
  ): Observable<IWHMaterialPageResultDTO> {
    return this.httpClient.get<IWHMaterialPageResultDTO>(
      `${environment.apiUrl + MATERIAL_GATEWAY + '/v1'}/material`,
      { params: httpParams },
    );
  }

  public getMaterialList(
    httpParams: HttpParams,
  ): Observable<IWHPageableMaterialDTO> {
    return this.httpClient.get<IWHPageableMaterialDTO>(
      `${environment.apiUrl + MATERIAL_GATEWAY + '/v1'}/material`,
      { params: httpParams },
    );
  }

  public getMaterialDtoByMaterialId2(
    materialId: string,
  ): Observable<MaterialDTO> {
    return this.httpClient.get<MaterialDTO>(
      `${environment.apiUrl + MATERIAL_GATEWAY + '/v1'}/material/${materialId}`,
    );
  }

  public getMaterialCatalogPreview(
    workStepId: string,
    httpParams: HttpParams,
  ): Observable<IWHMaterialPageResultDTO> {
    return this.httpClient.get<IWHMaterialPageResultDTO>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workSteps/${workStepId}/materialcatalog/preview`,
      { params: httpParams },
    );
  }

  // new materialService
  public getMaterialsByWorkStepId(workStepId: string) {
    this.workStepMaterialListEntity.loading$.next(true);
    return this.httpClient
      .get<WorkstepMaterialList>(
        `${
          environment.apiUrl + MATERIAL_GATEWAY
        }/v1/material/workstep/${workStepId}`,
      )
      .pipe(
        map((data) => {
          this.workStepMaterialListEntity.loading$.next(false);
          this.workStepMaterialListEntity.data$.next(data);
        }),
        catchError((error): any => {
          this.workStepMaterialListEntity.loading$.next(false);
          return new Error(error?.message);
        }),
      );
  }

  public assignMaterialsToWorkStep(payload: MaterialsToWorkStepPayload) {
    return this.httpClient.post<WorkstepMaterialList>(
      `${environment.apiUrl + MATERIAL_GATEWAY}/v1/material/workstep/${
        payload.workStepId
      }/assignments`,
      payload,
    );
  }

  public makeReservation(payload: MaterialsReservationPayload) {
    return this.httpClient.post<WorkstepMaterialList>(
      `${environment.apiUrl + MATERIAL_GATEWAY}/v1/reservation`,
      payload,
    );
  }

  public isMaterialAssigned(workstepId: string) {
    return this.httpClient.get<boolean>(
      `${
        environment.apiUrl + MATERIAL_GATEWAY
      }/v1/reservation/workstep/${workstepId}/hasReservedMaterials`,
    );
  }
}

export interface IWHPageableMaterialDTO extends Pageable {
  content: MaterialDTO[];
  sort: SortPageable[];
}

export interface SortPageable {
  ascending: boolean;
  descending: boolean;
  direction: 'asc' | 'desc';
  ignoreCase: boolean;
  nullHandling: string;
  property: string;
}

export interface MaterialDTO {
  id: string;
  extId: string;
  title: string;
  description: string;
  inventoryType: string;
  pictureWhFileId: string;
  pictureURL: string;
  thumbnailURL: string;
  serialNumberRequired: boolean;
  unit: string;
  status: string;
}
