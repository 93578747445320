// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHBryEventDOM, WHHttpParams } from '@workheld/workheld-shared-lib';

export enum WHBryEventStoreActionTypes {
  GetWHWorkObjectTeamPlannerEventDTOList = '[WHBryEventStore] GetWHWorkObjectTeamPlannerEventDTOList',
  GetWHWorkOrderTeamPlannerEventDTOList = '[WHBryEventStore] GetWHWorkOrderTeamPlannerEventDTOList',
  GetWHWorkOrderTeamPlannerCategoryEventDTOList = '[WHBryEventStore] GetWHWorkOrderTeamPlannerCategoryEventDTOList',
  SetWHBryEventDOMList = '[WHBryEventStore] SetWHBryEventDOMList',
}

export class GetWHWorkObjectTeamPlannerEventDTOList implements Action {
  readonly type =
    WHBryEventStoreActionTypes.GetWHWorkObjectTeamPlannerEventDTOList;

  constructor(
    public payload: {
      startDate: string;
      endDate: string;
      params?: WHHttpParams;
    },
  ) {
    // console.log('GetWHWorkObjectTeamPlannerEventDTOList: ' + JSON.stringify(payload));
  }
}

export class GetWHWorkOrderTeamPlannerEventDTOList implements Action {
  readonly type =
    WHBryEventStoreActionTypes.GetWHWorkOrderTeamPlannerEventDTOList;

  constructor(
    public payload: {
      startDate: string;
      endDate: string;
      params?: WHHttpParams;
    },
  ) {
    // console.log('GetWHWorkOrderTeamPlannerEventDTOList: ' + JSON.stringify(payload));
  }
}

export class GetWHWorkOrderTeamPlannerCategoryEventDTOList implements Action {
  readonly type =
    WHBryEventStoreActionTypes.GetWHWorkOrderTeamPlannerCategoryEventDTOList;

  constructor(
    public payload: {
      startDate: string;
      endDate: string;
      params?: WHHttpParams;
    },
  ) {
    // console.log('GetWHWorkOrderTeamPlannerEventDTOList: ' + JSON.stringify(payload));
  }
}

export class SetWHBryEventDOMList implements Action {
  readonly type = WHBryEventStoreActionTypes.SetWHBryEventDOMList;

  constructor(
    public payload: {
      events: WHBryEventDOM[];
      loading: boolean;
      loaded: boolean;
    },
  ) {
    // console.log('SetWHBryEventDOMList: ' + JSON.stringify(payload));
  }
}

export type WHBryEventStoreActions =
  | GetWHWorkObjectTeamPlannerEventDTOList
  | GetWHWorkOrderTeamPlannerEventDTOList
  | GetWHWorkOrderTeamPlannerCategoryEventDTOList
  | SetWHBryEventDOMList;
