// ANGULAR
import { FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// HELPER
import {
  haversineDistance,
  IWHFlowMetadataTranslationModel,
  WHNgxToastrENUM,
  WHNgxToastrService,
  WHFlowMetadataDOM,
  WHMetadataDataService,
  WHIconENUM,
  IWHUIConfigModel,
  IWHLocationDTO,
  WHNgxDndDraggableModel,
} from '@workheld/workheld-shared-lib';
import { WHDndBehaviorSubjectHelperService } from 'src/app/app-services-helper/w-h-dnd-behavior-subject-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'ng-bee-w-h-agm-core-location-form',
  templateUrl: './w-h-agm-core-location-form.component.html',
  styleUrls: ['./w-h-agm-core-location-form.component.scss'],
})
export class WHAgmCoreLocationFormComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  // ENUMS
  public COUNTRY: IWHUIConfigModel[] = [];
  public supportedCountryIsoEnums: string[] = [];

  // LOCATION FORM GROUP
  @Input() public locationFormGroup: FormGroup;

  // DND SUPPORT
  @Input() public supportDragNDrop: boolean = false;
  public draggable: WHNgxDndDraggableModel;

  // INPUT STATE
  @Input() public searchTabIndex: number = 0;
  @Input() public formattedAddressTabIndex: number = 0;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  // VAR
  public zoom: number = 15;
  private defaultLatitude: number = 48.2081743;
  private defaultLongitude: number = 16.37381890000006;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChild(MapMarker) marker: any;

  constructor(
    // private mapsAPILoader: MapsAPILoader,
    private metadataDataService: WHMetadataDataService,
    private ngxToastrService: WHNgxToastrService,
    private dataModelHelperService: DataModelHelperService,
    private dndBehaviorSubjectHelperService: WHDndBehaviorSubjectHelperService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}
  ngAfterViewInit(): void {
    if (this.map?.googleMap) {
      console.log(this.map.googleMap);
      this.map.googleMap.setOptions({
        streetViewControl: false,
        mapTypeControl: false,
      });
    }
  }

  public ngOnInit(): void {
    // DND SUPPORT
    this.subscriptions.push(
      this.dndBehaviorSubjectHelperService.dndBehaviorSubject.subscribe(
        (draggable: WHNgxDndDraggableModel) => {
          if (this.supportDragNDrop) {
            this.draggable = draggable;
          }
        },
      ),
    );

    // ENUM TRANSLATIONS
    this.subscriptions.push(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.COUNTRY = metadataTranslation.COUNTRY
            ? metadataTranslation.COUNTRY
            : [];
          this.changeDetectorRef.detectChanges();
        },
      ),
    );

    // METADATA - LOCATION ENUMS
    this.subscriptions.push(
      this.metadataDataService.metadataDOM$.subscribe(
        (metadataDOM: WHFlowMetadataDOM) => {
          this.supportedCountryIsoEnums = metadataDOM?.countries
            ? metadataDOM.countries
            : [];
          this.changeDetectorRef.detectChanges();
        },
      ),
    );

    // EXACT ADDRESS
    this.subscriptions.push(
      this.locationFormGroup.controls[
        'formattedAddress'
      ].statusChanges.subscribe((status: any) => {
        if (this.locationFormGroup.controls['formattedAddress'].dirty) {
          this.locationFormGroup.controls['exact'].patchValue(false);
        }
      }),
    );
    this.subscriptions.push(
      this.locationFormGroup.controls['exact'].valueChanges.subscribe(
        (value: any) => {
          if (value) {
            this.infoWindow.close();
          } else {
            this.infoWindow.open(this.marker, false);
          }
        },
      ),
    );

    this.subscriptions.push(
      this.locationFormGroup.controls['countryCodeISO'].statusChanges.subscribe(
        (status: any) => {
          if (this.locationFormGroup.controls['countryCodeISO'].dirty) {
            this.locationFormGroup.controls['exact'].patchValue(false);
          }
        },
      ),
    );
  }

  // USING PLACE in GOOGLE MAPS
  public placeChanged($event: IWHLocationDTO) {
    const locationDTO: IWHLocationDTO = $event;
    const locationSupplement: IWHLocationDTO = this.locationFormGroup.controls[
      'locationSupplement'
    ].value as IWHLocationDTO;
    this.locationFormGroup.reset();
    this.locationFormGroup.patchValue(locationDTO);
    this.locationFormGroup.controls['locationSupplement'].patchValue(
      locationSupplement,
    );

    this.changeDetectorRef.detectChanges();
    this.map.googleMap.setCenter({ lat: this.latitude, lng: this.longitude });
  }

  // DRAG PIN
  public markerDragEnd(event: google.maps.MapMouseEvent) {
    this.locationFormGroup.controls['latitude'].patchValue(event.latLng.lat());
    this.locationFormGroup.controls['longitude'].patchValue(event.latLng.lng());
    this.onMarkerDragEnd(event);
  }

  private onMarkerDragEnd(event: google.maps.MapMouseEvent) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(
      event.latLng.lat(),
      event.latLng.lng(),
    );
    if (navigator.geolocation) {
      geocoder.geocode(
        { location: latlng },
        (results: google.maps.GeocoderResult[], status) => {
          if (status === 'OK' && results) {
            const locationDTO: IWHLocationDTO = this.getLocation(results);

            const supportedCountryISO: string = this.getCountryISO(
              locationDTO.countryCodeISO.toLowerCase(),
            );

            if (supportedCountryISO) {
              const isLocationExact: boolean = this.setExactAddress(
                locationDTO,
                event,
              );
              // INFO that is not exact LOCATION
              isLocationExact
                ? console.log()
                : this.ngxToastrService.displayToastr({
                    toastrType: WHNgxToastrENUM.INFO,
                    messageTranslateKey: 'location.ui.notexact.notification',
                  });

              this.locationFormGroup.controls['exact'].patchValue(
                isLocationExact,
              );

              this.changeDetectorRef.detectChanges();
            } else {
              this.sendWarningMessage();
            }
          }
        },
      );
    }
  }

  private getLocation(results): IWHLocationDTO {
    const formattedAddress: string = this.locationFormGroup.controls[
      'formattedAddress'
    ].value as string;

    return this.dataModelHelperService.initLocationDtoFromGoogleGeocoderResultArray(
      results,
      formattedAddress,
    );
  }

  private getCountryISO(countryCodeISO: string) {
    return this.supportedCountryIsoEnums.find((countryISO: string) => {
      return countryISO.toLowerCase() === countryCodeISO.toLowerCase();
    });
  }

  public geoLocationSearch() {
    if (!this.isGeoSet) {
      return;
    }
    const fakeany: any = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.reverseGeocode(fakeany);
  }

  // REVERSE GEOCODE
  private reverseGeocode(coords: any) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(coords.lat, coords.lng);
    if (navigator.geolocation) {
      geocoder.geocode(
        { location: latlng },
        (results: google.maps.GeocoderResult[], status) => {
          if (status === 'OK' && results) {
            const locationDTO: IWHLocationDTO = this.getLocation(results);

            const supportedCountryISO: string = this.getCountryISO(
              locationDTO.countryCodeISO.toLowerCase(),
            );

            if (supportedCountryISO) {
              const isLocationExact: boolean = this.setExactAddress(
                locationDTO,
                coords,
              );

              // INFO that is not exact LOCATION
              this.locationFormGroup.controls['name'].reset();

              this.locationFormGroup.controls['latitude'].patchValue(
                coords.lat,
              );
              this.locationFormGroup.controls['longitude'].patchValue(
                coords.lng,
              );

              this.locationFormGroup.controls['formattedAddress'].patchValue(
                locationDTO.formattedAddress,
              );
              this.locationFormGroup.controls['countryCodeISO'].patchValue(
                locationDTO.countryCodeISO,
              );

              isLocationExact
                ? console.log()
                : this.ngxToastrService.displayToastr({
                    toastrType: WHNgxToastrENUM.INFO,
                    messageTranslateKey:
                      'clienterror.location.notexact.notification',
                  });

              this.locationFormGroup.controls['exact'].patchValue(
                isLocationExact,
              );
              this.changeDetectorRef.detectChanges();
            } else {
              this.sendWarningMessage('ERROR.COUNTRIES_NOT_SET');
            }
          }
        },
      );
      this.map.googleMap.setCenter({ lat: this.latitude, lng: this.longitude });
    }
  }

  center: google.maps.LatLngLiteral = {
    lat: 24,
    lng: 12,
  };
  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };
  markerPositions: google.maps.LatLngLiteral[] = [];
  addMarker(event: google.maps.MapMouseEvent) {
    const coords = event.latLng.toJSON();
    if (event.latLng != null) this.markerPositions.push(event.latLng.toJSON());

    this.locationFormGroup.controls['latitude'].patchValue(coords.lat);
    this.locationFormGroup.controls['longitude'].patchValue(coords.lng);
    this.reverseGeocode(coords);
  }

  private sendWarningMessage(
    countryNotSupportedKey: string = 'location.ui.countrynotsupported.notification',
  ) {
    const countryNotSupported =
      !this.supportedCountryIsoEnums ||
      this.supportedCountryIsoEnums.length === 0;
    if (countryNotSupported) {
      // ENUMS NOT SET!
      this.ngxToastrService.displayToastr({
        toastrType: WHNgxToastrENUM.WARNING,
        messageTranslateKey: countryNotSupportedKey,
      });
    } else {
      this.ngxToastrService.displayToastr({
        toastrType: WHNgxToastrENUM.INFO,
        messageTranslateKey: 'ERROR.CATALOG_BR_LOCATION_2',
      });
    }
  }

  private setExactAddress(locationDTO: IWHLocationDTO, coords: any): boolean {
    const mk1: {
      lat: number;
      lng: number;
    } = {
      lat: locationDTO.latitude,
      lng: locationDTO.longitude,
    };

    const mk2: {
      lat: number;
      lng: number;
    } = {
      lat: coords.lat,
      lng: coords.lng,
    };

    const distance = haversineDistance(mk1, mk2);
    console.log(distance * 1000);
    if (distance * 1000 <= 9) {
      return true;
    } else {
      return false;
    }
  }

  public get activeZoom(): number {
    return this.isGeoSet ? this.zoom : 8;
  }

  public get isGeoSet(): boolean {
    return (
      !!this.locationFormGroup.controls['longitude'].value &&
      !!this.locationFormGroup.controls['latitude'].value
    );
  }

  public get formattedAddress(): string {
    return this.locationFormGroup.controls['formattedAddress'].value
      ? this.locationFormGroup.controls['formattedAddress'].value
      : '';
  }

  public get isExact(): boolean {
    return this.locationFormGroup.controls['exact'].value;
  }

  public get latitude(): number {
    return this.locationFormGroup.controls['latitude'].value
      ? +this.locationFormGroup.controls['latitude'].value
      : this.defaultLatitude;
  }

  public get longitude(): number {
    return this.locationFormGroup.controls['longitude'].value
      ? +this.locationFormGroup.controls['longitude'].value
      : this.defaultLongitude;
  }

  public get locationByGeoIcon(): string {
    return WHIconENUM.LocationByGeoIcon as string;
  }

  public get locationExactIcon(): string {
    return WHIconENUM.ExactAddressPinIcon as string;
  }

  public get locationNotExactIcon(): string {
    return WHIconENUM.UnexactAddressPin as string;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
