<div>
  <div class="row-wrapper entry-detail-row-wrapper">
    <span>
      <ng-bee-customer-static-data
        [withLabel]="true"
        [detailURL]="
          entryDOM?.customerCachedDetailURL ||
          entryDOM?.workObjectDetails?.customerDetailURL
        "
      >
      </ng-bee-customer-static-data>
    </span>
    @if (!entryDOM.workObjectDetails?.clientUrl) {
      <span>
        <ng-bee-work-object-static-data
          [isSimpleData]="true"
          [withTitleLabel]="true"
          [detailURL]="
            entryDOM?.workObjectCachedDetailURL ||
            entryDOM?.generatedWorkObjectDetailURL
          "
        >
        </ng-bee-work-object-static-data>
      </span>
    } @else {
      <div class="flex--col">
        <div class="w-h-label html-overflow" style="margin-bottom: 0px">
          {{ "workobject.model.root" | translate }}
        </div>
        <div class="flex--c-c-l">
          <button
            style="width: 100%; max-width: max-content"
            mat-button
            color="primary"
            disableRipple
            matTooltip="{{ entryDOM?.generatedWorkObjectExtID }} {{
              entryDOM?.workObjectDetails?.title
            }}"
            (click)="navigateToProjectDetails()"
          >
            <div class="html-overflow" style="max-width: unset">
              {{ entryDOM?.generatedWorkObjectExtID }} -
              {{ entryDOM?.workObjectDetails?.title }}
            </div>
            <mat-icon iconPositionEnd>open_in_new</mat-icon>
          </button>
        </div>
      </div>
    }
    <span>
      <ng-bee-work-order-static-data
        [withLabel]="true"
        [detailURL]="entryDOM?.workOrderCachedDetailURL"
      >
      </ng-bee-work-order-static-data>
    </span>
    <span>
      <ng-bee-work-step-static-data
        [withLabel]="true"
        [detailURL]="entryDOM?.workStepCachedDetailURL"
      >
      </ng-bee-work-step-static-data>
    </span>
  </div>
  <mat-divider></mat-divider>
  <div [ngSwitch]="entryDOM?.entryTypeId" class="w-h-entry-static-data">
    <div *ngSwitchCase="1">
      <ng-bee-type1-travel-entry-static-data
        [entryDOM]="entryDOM"
      ></ng-bee-type1-travel-entry-static-data>
    </div>
    <div *ngSwitchCase="2">
      <ng-bee-type2-working-time-entry-static-data
        [entryDOM]="entryDOM"
      ></ng-bee-type2-working-time-entry-static-data>
    </div>
    <div *ngSwitchCase="3">
      <ng-bee-type3-expence-entry-static-data
        [entryDOM]="entryDOM"
      ></ng-bee-type3-expence-entry-static-data>
    </div>
    <div *ngSwitchCase="4">
      <ng-bee-type4-defect-entry-static-data
        [entryDOM]="entryDOM"
      ></ng-bee-type4-defect-entry-static-data>
    </div>
    <div *ngSwitchCase="5">
      <ng-bee-type5-material-consumption-entry-static-data
        [entryDOM]="entryDOM"
      ></ng-bee-type5-material-consumption-entry-static-data>
    </div>
    <div *ngSwitchCase="6">
      <ng-bee-type6-measurment-entry-static-data
        [entryDOM]="entryDOM"
      ></ng-bee-type6-measurment-entry-static-data>
    </div>
    <div *ngSwitchCase="7">
      <ng-bee-type7-site-diary-entry-static-data
        [entryDOM]="entryDOM"
      ></ng-bee-type7-site-diary-entry-static-data>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
