import { Component, effect, input, OnInit, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AppDialogService } from 'src/app/app-dialogs/app-dialog.service';
import {
  IWHWorkOrderDTO,
  WHNgxToastrENUM,
  WHNgxToastrService,
  WHWorkOrderDOM,
} from '@workheld/workheld-shared-lib';
import { WorkOrderAsyncService } from 'src/app/app-services-async/work-order-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { SetWHWorkOrderDOM } from 'src/app/app-stores/w-h-work-order-stores/w-h-work-order-crud-store/w-h-work-order-crud-store.actions';
import { IAppStore } from 'src/app/app-stores/index.stores';
import { Store } from '@ngrx/store';
import { MatIconModule } from '@angular/material/icon';
import { ALERT_OPTIONS } from 'src/app/app-dialogs/mat-dialog-remind-technician-settings/mat-dialog-remind-technician-settings.component';
import { isFuture, subMinutes } from 'date-fns';
import { UIService } from 'src/app/app-services/ui.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { dynamicAnimation } from '../../helpers/animations';
import { catchError, EMPTY, forkJoin, map, throwError } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  IWHWorkOrderMasterDataStore,
  workheldWorkOrderMasterDataStoreFeatureKey,
} from 'src/app/app-stores/w-h-work-order-stores/w-h-work-order-master-data-store/w-h-work-order-master-data-store.reducer';

@Component({
  selector: 'w-h-remind-technician-notification-settings',
  standalone: true,
  styles: `
    .card {
      border: 1px solid #e3e3e3;
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;
    }
  `,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  animations: [dynamicAnimation],
  template: `
    @if (
      !selectedReminderTime()?.option &&
      selectedReminderTime()?.option?.value !== 0
    ) {
      <button mat-stroked-button color="accent" (click)="openSettingsModal()">
        {{ 'app.ui.createNotification.label' | translate }}
      </button>
    } @else {
      <div
        [@dynamicAnimation]="'left'"
        class="card"
        (click)="openSettingsModal()"
      >
        @if (loading()) {
          <div class="flex--r-c-c flex-1">
            <mat-spinner diameter="20"></mat-spinner>
          </div>
        } @else {
          <div class="flex--col gap--16">
            <div class="flex--r-fs gap--8">
              <mat-icon style="min-width: 28px" color="accent"
                >notifications</mat-icon
              >
              <div class="flex--col gap--4">
                @if (selectedReminderTime()?.option) {
                  <span>
                    <b>{{
                      selectedReminderTime().option.translateKey | translate
                    }}</b>

                    @if (selectedReminderTime().schedulerNotificationDate) {
                      <i
                        >({{
                          selectedReminderTime().schedulerNotificationDate
                            | date: 'short' : '' : $currentLocale()
                        }})</i
                      >
                    }
                  </span>
                  <span
                    [matTooltip]="selectedReminderTime()?.reminderComment"
                    style="
                  word-break: break-all;
                  display: -webkit-box;
                  line-clamp: 5;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                "
                  >
                    {{ selectedReminderTime()?.reminderComment }}
                  </span>
                }
              </div>
            </div>
            @if (isMultipleNotificationsConfigured) {
              <div class="messages-container info gap--8 flex--r-c">
                <mat-icon>info</mat-icon>
                {{ 'notification.multipleConfigured.info' | translate }}
              </div>
            }
          </div>
        }
      </div>
    }
  `,
})
@UntilDestroy()
export class WhRemindTechnicianNotificationSettingsComponent implements OnInit {
  workOrderDOM = input<WHWorkOrderDOM>();
  bulkAction = input<boolean>(false);
  hideDeleteButton = input<boolean>(false);
  alertOptions = ALERT_OPTIONS;
  selectedReminderTime = signal({
    option: null,
    reminderComment: null,
    schedulerNotificationDate: '',
  });
  $currentLocale = signal('en');
  loading = signal(false);
  workOrderDOMList: WHWorkOrderDOM[];
  isMultipleNotificationsConfigured: boolean;
  constructor(
    private appDialogService: AppDialogService,
    private workOrderAsyncService: WorkOrderAsyncService,
    private dataModelHelperService: DataModelHelperService,
    private store: Store<IAppStore>,
    private uiService: UIService,
    private ngxToastrService: WHNgxToastrService,
  ) {
    effect(
      () => {
        if (
          this.workOrderDOM() &&
          (this.workOrderDOM().reminderOffsetInMinutes ||
            this.workOrderDOM().reminderOffsetInMinutes === 0)
        ) {
          this.calculateSchedulerNotificationDate(
            this.workOrderDOM(),
            this.workOrderDOM().reminderOffsetInMinutes,
            this.workOrderDOM().reminderComment,
          );
        }
      },
      { allowSignalWrites: true },
    );
  }
  ngOnInit(): void {
    this.$currentLocale = this.uiService.$currentLocale;

    if (this.bulkAction()) {
      this.store
        .select(workheldWorkOrderMasterDataStoreFeatureKey)
        .pipe(untilDestroyed(this))
        .subscribe(
          (workheldWorkOrderMasterDataStore: IWHWorkOrderMasterDataStore) => {
            this.workOrderDOMList =
              workheldWorkOrderMasterDataStore?.workOrderDomList?.filter(
                (workOrder) =>
                  workOrder.scheduledStartDate &&
                  workOrder.calculatedWorkOrderStatusId === 0 &&
                  isFuture(new Date(workOrder.scheduledStartDate)),
              );
            this.isMultipleNotificationsConfigured =
              this.workOrderDOMList?.some(
                (workOrder) =>
                  workOrder.reminderOffsetInMinutes !==
                  this.workOrderDOMList[0].reminderOffsetInMinutes,
              );
            if (this.workOrderDOMList.length > 0) {
              this.calculateSchedulerNotificationDate(
                this.workOrderDOMList[0],
                this.workOrderDOMList[0].reminderOffsetInMinutes,
                this.workOrderDOMList[0].reminderComment,
              );
            }
          },
        );
    }
  }

  openSettingsModal() {
    const workOrders = this.bulkAction()
      ? this.workOrderDOMList
      : [this.workOrderDOM()];

    const {
      scheduledStartDate,
      calculatedWorkOrderStatusId,
      reminderComment,
      reminderOffsetInMinutes,
    } = workOrders[0] ?? {};

    this.appDialogService
      .openRemindTechnicianSettingsDialog({
        scheduledStartDate,
        calculatedWorkOrderStatusId,
        reminderComment: this.isMultipleNotificationsConfigured
          ? null
          : (this.selectedReminderTime().reminderComment ?? reminderComment),
        reminderOffsetInMinutes: this.isMultipleNotificationsConfigured
          ? null
          : reminderOffsetInMinutes,
        hideDeleteButton: this.hideDeleteButton(),
        bulkAction: this.bulkAction(),
        showNoneMessage:
          this.bulkAction() && this.workOrderDOMList.length === 0,
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        if (!settings) return;

        this.loading.set(true);

        if (this.bulkAction()) {
          forkJoin(
            workOrders.map((workOrder) =>
              this.workOrderAsyncService.updateWorkOrder(
                workOrder.id,
                settings,
              ),
            ),
          )
            .pipe(
              untilDestroyed(this),
              map((updatedWorkOrders) => {
                this.workOrderDOMList = updatedWorkOrders as any;
                this.isMultipleNotificationsConfigured =
                  this.workOrderDOMList?.some(
                    (workOrder) =>
                      workOrder.reminderOffsetInMinutes !==
                      this.workOrderDOMList[0].reminderOffsetInMinutes,
                  );

                updatedWorkOrders.forEach((workOrderDTO) => {
                  const workOrderDOM: WHWorkOrderDOM =
                    this.dataModelHelperService.initWorkOrderDOM(workOrderDTO);
                  this._handleSuccessUpdate(workOrderDOM, settings);
                });
                this.loading.set(false);
              }),
              catchError((error) => {
                console.error('Bulk update failed', error);
                this.loading.set(false);
                return EMPTY;
              }),
            )
            .subscribe();
        } else {
          this.workOrderAsyncService
            .updateWorkOrder(workOrders[0].id, settings)
            .pipe(
              untilDestroyed(this),
              catchError((error) => throwError(error)),
            )
            .subscribe((workOrderDTO: IWHWorkOrderDTO) => {
              const workOrderDOM: WHWorkOrderDOM =
                this.dataModelHelperService.initWorkOrderDOM(workOrderDTO);
              this._handleSuccessUpdate(workOrderDOM, settings);
              this.loading.set(false);
            });
        }
      });
  }

  private _handleSuccessUpdate(workOrderDOM: WHWorkOrderDOM, settings) {
    if (
      workOrderDOM.reminderOffsetInMinutes ||
      workOrderDOM.reminderOffsetInMinutes === 0
    ) {
      this.ngxToastrService.displayToastr({
        toastrType: WHNgxToastrENUM.SUCCESS,
        messageTranslateKey: 'notifications.ui.updatesuccess',
      });
    } else if (
      !workOrderDOM.reminderOffsetInMinutes &&
      workOrderDOM.reminderOffsetInMinutes !== 0
    ) {
      this.ngxToastrService.displayToastr({
        toastrType: WHNgxToastrENUM.SUCCESS,
        messageTranslateKey: 'notifications.ui.deletesuccess',
      });
    } else {
      this.ngxToastrService.displayToastr({
        toastrType: WHNgxToastrENUM.SUCCESS,
        messageTranslateKey: 'notifications.ui.createsuccess',
      });
    }

    if (!this.bulkAction()) {
      this.store.dispatch(new SetWHWorkOrderDOM(workOrderDOM));
    }

    this.calculateSchedulerNotificationDate(
      workOrderDOM,
      settings.reminderOffsetInMinutes,
      workOrderDOM.reminderComment,
    );
  }

  private calculateSchedulerNotificationDate(
    workOrderDOM: WHWorkOrderDOM,
    reminderOffsetInMinutes,
    reminderComment: string,
  ) {
    if (
      this.bulkAction() &&
      this.workOrderDOMList.length > 0 &&
      this.isMultipleNotificationsConfigured
    ) {
      this.selectedReminderTime.set({
        option: { translateKey: 'notification.multipleConfigured.label' },
        reminderComment: null,
        schedulerNotificationDate: '',
      });
      return;
    }
    const option = this.alertOptions.find(
      (option) => option?.value === reminderOffsetInMinutes,
    );
    this.selectedReminderTime.set({
      option,
      reminderComment,
      schedulerNotificationDate: subMinutes(
        new Date(workOrderDOM.scheduledStartDate),
        option?.value,
      ).toString(),
    });
  }
}
