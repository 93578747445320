import { Injectable, signal } from '@angular/core';
import { IWHWorkObjectCrudStore } from '../app-stores/w-h-work-object-stores/w-h-work-object-crud-store/w-h-work-object-crud-store.reducer';
import {
  IWHCustomerDTO,
  WHCustomerDOM,
  IWHCustomerPreviewDTO,
  WHCustomerPreviewDOM,
  WHFileDOM,
  WHWorkObjectTypeENUM,
  IWHBomNodeDTO,
  WHNestedNodeDOM,
  IWHWorkerTimeReportResultDTO,
  WHWorkerTimeReportResultDOM,
  IWHEquipmentPreviewDTO,
  WHEquipmentPreviewDOM,
  IWHEquipmentDTO,
  WHEquipmentDOM,
  IWHMaterialPageResultDTO,
  WHMaterialPageResultDOM,
  WHMaterialDOM,
  IWHMaterialDTO,
  IWHMaterialInventoryDTO,
  WHMaterialInventoryDOM,
  IWHToolsPreviewDTO,
  WHToolPreviewDOM,
  IWHToolDTO,
  WHToolDOM,
  IWHFlowMetadataDTO,
  WHFlowMetadataDOM,
  IWHWorkObjectPreviewDTO,
  WHWorkObjectPreviewDOM,
  WHChecklistDefinitionDOM,
  WHWorkObjectTemplatePreviewDOM,
  IWHWorkObjectTemplatePreviewDTO,
  IWHWorkObjectTemplateDTO,
  WHWorkObjectTemplateDOM,
  IWHChecklistDefinitionDTO,
  WHChecklistInstancePreviewDOM,
  IWHChecklistInstancePreviewDTO,
  IWHChecklistInstanceDTO,
  WHChecklistInstanceDOM,
  IWHWorkerDTO,
  IWHLocationDTO,
  WHCheckInstanceFileDOM,
  IWHCheckInstanceFileDTO,
  WHWorkerDOM,
  IWHEntryPreviewDTO,
  WHEntryPreviewDOM,
  WHTravelEntryDOM,
  IWHTravelEntryDTO,
  IWHWorkingTimEntryDTO,
  WHWorkingTimEntryDOM,
  IWHExpenseEntryDTO,
  WHExpenseEntryDOM,
  IWHDefectEntryDTO,
  WHDefectEntryDOM,
  IWHMaterialConsumptionEntryDTO,
  WHMaterialConsumptionEntryDOM,
  IWHMeasurementEntryDTO,
  WHMeasurementEntryDOM,
  IWHEntryDTO,
  WHEntryDOM,
  IWHWorkObjectDTO,
  WHWorkObjectDOM,
  IWHWorkOrderFileDTO,
  IWHCaseWorkObjectPreviewDTO,
  WHCaseWorkObjectPreviewDOM,
  IWHCaseWorkObjectDTO,
  WHProjectWorkObjectDOM,
  IWHProjectWorkObjectDTO,
  IWHProjectWorkObjectPreviewDTO,
  WHProjectWorkObjectPreviewDOM,
  WHCaseWorkObjectDOM,
  IWHEntryFilePreviewDTO,
  WHEntryFilePreviewDOM,
  IWHWorkOrderDTO,
  WHWorkOrderDOM,
  IWHEntryFileDTO,
  IWHStandingOrderWorkObjectDTO,
  WHStandingOrderWorkObjectDOM,
  IWHStandingOrderWorkObjectPreviewDTO,
  WHStandingOrderWorkObjectPreviewDOM,
  WHEntryFileDOM,
  IWHWorkStepDTO,
  WHWorkStepDOM,
  WHReportFilePreviewDOM,
  IWHReportFilePreviewDTO,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class DataModelHelperService {
  materialInventoryType = signal('');

  // REMOVE NULL FIELDS
  public removeObjectNullFields(dataObject: any): any {
    const objectPayload: any = {};
    Object.entries(dataObject).forEach((property: any[]) => {
      if (
        property[1] !== null &&
        property[1] !== undefined &&
        property[1] !== ''
      ) {
        // property[0]: key; property[1]: value
        objectPayload[property[0]] = property[1];
      }
    });
    return objectPayload;
  }

  // LOCATION - PLACE
  public initLocationDtoFromGooglePlaceResult(
    place: google.maps.places.PlaceResult,
  ): IWHLocationDTO {
    // console.log(place);
    // RETURN ONLY FIELDS GOOGLE SEARCH CAN AFFECT
    return {
      name: place.name ? place.name : null,
      formattedAddress: place.formatted_address,
      latitude: +place.geometry.location.lat(),
      longitude: +place.geometry.location.lng(),
      countryCodeISO: this.initLocationCountryISO(place.address_components),
      exact: true,
    } as any;
  }

  // LOCATION - GEOCODER
  public initLocationDtoFromGoogleGeocoderResultArray(
    geocoderResults: google.maps.GeocoderResult[],
    formattedAddress?: string,
  ): IWHLocationDTO {
    let geocoderResult: google.maps.GeocoderResult;

    if (formattedAddress) {
      geocoderResult = geocoderResults.find(
        (geocoderResultFind: google.maps.GeocoderResult) => {
          return geocoderResultFind.formatted_address === formattedAddress;
        },
      );
    }
    // console.log(geocoderResult);
    // RETURN ONLY FIELDS GOOGLE SEARCH CAN AFFECT
    return {
      // name: null, // FILLED IN COMPONENT
      formattedAddress: geocoderResult
        ? geocoderResult.formatted_address
        : geocoderResults[0]?.formatted_address,
      latitude: geocoderResult
        ? geocoderResult.geometry.location.lat()
        : geocoderResults[0]?.geometry.location.lat(),
      longitude: geocoderResult
        ? geocoderResult.geometry.location.lng()
        : geocoderResults[0]?.geometry.location.lng(),
      countryCodeISO: geocoderResult
        ? this.initLocationCountryISO(geocoderResult.address_components)
        : this.initLocationCountryISO(geocoderResults[0]?.address_components),
      // exact: false // FILLED IN COMPONENT
    } as any;
  }

  private initLocationCountryISO(
    geocoderAddressComponents: google.maps.GeocoderAddressComponent[],
  ): string {
    let countryCodeISO: string;
    geocoderAddressComponents.forEach(
      (addressComponent: {
        long_name: string;
        short_name: string;
        types: string[];
      }) => {
        if (addressComponent.types && addressComponent.types.length > 0) {
          const countryTypeString: string = addressComponent.types.find(
            (type) => type === 'country',
          );

          if (!!countryTypeString) {
            countryCodeISO = addressComponent.short_name;
          }
        }
      },
    );
    return countryCodeISO;
  }

  // METADATA
  public initMetadataDOM(metadataDTO?: IWHFlowMetadataDTO): WHFlowMetadataDOM {
    return new WHFlowMetadataDOM(metadataDTO);
  }

  // EQUIPMENT
  public initEquipmentDOM(equipmentDTO: IWHEquipmentDTO): WHEquipmentDOM {
    return new WHEquipmentDOM(equipmentDTO);
  }

  // EQUIPMENT NODE
  public initEquipmentNodeDOM(equipmentDTO: IWHBomNodeDTO): WHNestedNodeDOM {
    return new WHNestedNodeDOM(equipmentDTO);
  }

  public initEquipmentPreviewDOM(
    equipmentPreviewDTO: IWHEquipmentPreviewDTO,
  ): WHEquipmentPreviewDOM {
    return new WHEquipmentPreviewDOM(equipmentPreviewDTO);
  }

  // CUSTOMER
  public initCustomerDOM(customerDTO: IWHCustomerDTO): WHCustomerDOM {
    return new WHCustomerDOM(customerDTO);
  }

  public initCustomerPreviewDOM(
    customerPreviewDTO: IWHCustomerPreviewDTO,
  ): WHCustomerPreviewDOM {
    return new WHCustomerPreviewDOM(customerPreviewDTO);
  }

  // MATERIAL
  public initMaterialDOM(materialDTO: IWHMaterialDTO): WHMaterialDOM {
    return new WHMaterialDOM(materialDTO);
  }

  public initMaterialPreviewDOM(
    materialPreviewDTO: IWHMaterialPageResultDTO,
  ): WHMaterialPageResultDOM {
    return new WHMaterialPageResultDOM(materialPreviewDTO);
  }

  public initMaterialInventoryDOM(
    materialInventoryDTO: IWHMaterialInventoryDTO,
  ): WHMaterialInventoryDOM {
    return new WHMaterialInventoryDOM(materialInventoryDTO);
  }

  // TOOL
  public initToolDOM(toolDTO: IWHToolDTO): WHToolDOM {
    return new WHToolDOM(toolDTO);
  }

  public initToolPreviewDOM(
    toolPreviewDTO: IWHToolsPreviewDTO,
  ): WHToolPreviewDOM {
    return new WHToolPreviewDOM(toolPreviewDTO);
  }

  // WORKER
  public initWorkerDOM(workerDTO: IWHWorkerDTO): WHWorkerDOM {
    return new WHWorkerDOM(workerDTO);
  }

  public initWorkerTimeReportResultDOM(
    workerTimeReportResultDTO: IWHWorkerTimeReportResultDTO,
  ): WHWorkerTimeReportResultDOM {
    return new WHWorkerTimeReportResultDOM(workerTimeReportResultDTO);
  }

  // CHECKLIST DEFINITION
  public initChecklistDefinitionDOM(
    checklistDefinitionDTO: IWHChecklistDefinitionDTO,
  ): WHChecklistDefinitionDOM {
    return new WHChecklistDefinitionDOM(checklistDefinitionDTO);
  }

  // CHECKLIST INSTANCE
  public initChecklistInstanceDOM(
    checklistInstanceDTO: IWHChecklistInstanceDTO,
  ): WHChecklistInstanceDOM {
    return new WHChecklistInstanceDOM(checklistInstanceDTO);
  }

  // CHECKLIST INSTANCE - CHECK INSTANCE FILE
  public intiCheckInstanceFileDOM(
    checkInstanceFileDTO: IWHCheckInstanceFileDTO,
  ): WHCheckInstanceFileDOM {
    return new WHCheckInstanceFileDOM(checkInstanceFileDTO);
  }

  public initChecklistInstancePreviewDOM(
    checklistInstancePreviewDTO: IWHChecklistInstancePreviewDTO,
  ): WHChecklistInstancePreviewDOM {
    return new WHChecklistInstancePreviewDOM(checklistInstancePreviewDTO);
  }

  // WORK OBJECTS
  public initWorkObjectPreviewDOM(
    workObjectPreviewDTO: IWHWorkObjectPreviewDTO,
  ): WHWorkObjectPreviewDOM {
    return new WHWorkObjectPreviewDOM(workObjectPreviewDTO);
  }

  public initWorkObjectTypeDOM(
    workObjectDTO: IWHWorkObjectDTO,
  ): WHWorkObjectDOM | any {
    return this.initWorkObjectType(workObjectDTO);
  }

  private initWorkObjectType(workObjectDTO: any): WHWorkObjectDOM | any {
    if (!workObjectDTO) {
      return null;
    }
    switch (workObjectDTO.workObjectTypeId) {
      case WHWorkObjectTypeENUM.PROJECT:
        return this.initProjectWorkObjectDOM(workObjectDTO);
      case WHWorkObjectTypeENUM.CASE:
        return this.initCaseWorkObjectDOM(workObjectDTO);
      case WHWorkObjectTypeENUM.STANDING_ORDER:
        return this.initStandingOrderWorkObjectDOM(workObjectDTO);
    }
  }

  public getWorkObjectTypeDOM(
    workObjectTypeId: number,
    workObjectCrudStore: IWHWorkObjectCrudStore,
  ): WHWorkObjectDOM | any {
    switch (workObjectTypeId) {
      case WHWorkObjectTypeENUM.PROJECT:
        return workObjectCrudStore.projectDOM;
      case WHWorkObjectTypeENUM.CASE:
        return workObjectCrudStore.caseDOM;
      case WHWorkObjectTypeENUM.STANDING_ORDER:
        return workObjectCrudStore.standingOrderDOM;
      case WHWorkObjectTypeENUM.JOB:
        return workObjectCrudStore.jobDOM;
    }
  }

  public initWorkObjectPreviewType(
    workObjectTypeId: number,
    workObjectPreviewDTO: any,
  ): any {
    switch (workObjectTypeId) {
      case WHWorkObjectTypeENUM.PROJECT:
        return this.initProjectWorkObjectPreviewDOM(workObjectPreviewDTO);
      case WHWorkObjectTypeENUM.CASE:
        return this.initCaseWorkObjectPreviewDOM(workObjectPreviewDTO);
      case WHWorkObjectTypeENUM.STANDING_ORDER:
        return this.initStandingOrderWorkObjectPreviewDOM(workObjectPreviewDTO);
    }
  }

  // WORK OBJECT - TYPE 1 - PROJECT
  public initProjectWorkObjectPreviewDOM(
    projectWorkObjectPreviewDTO: IWHProjectWorkObjectPreviewDTO,
  ): WHProjectWorkObjectPreviewDOM {
    return new WHProjectWorkObjectPreviewDOM(projectWorkObjectPreviewDTO);
  }

  public initProjectWorkObjectDOM(
    projectWorkObjectDTO: IWHProjectWorkObjectDTO,
  ): WHProjectWorkObjectDOM {
    return new WHProjectWorkObjectDOM(projectWorkObjectDTO);
  }

  // WORK OBJECT - TYPE 2 - CASE
  public initCaseWorkObjectPreviewDOM(
    caseWorkObjectPreviewDTO: IWHCaseWorkObjectPreviewDTO,
  ): WHCaseWorkObjectPreviewDOM {
    return new WHCaseWorkObjectPreviewDOM(caseWorkObjectPreviewDTO);
  }

  public initCaseWorkObjectDOM(
    caseWorkObjectDTO: IWHCaseWorkObjectDTO,
  ): WHCaseWorkObjectDOM {
    return new WHCaseWorkObjectDOM(caseWorkObjectDTO);
  }

  // WORK OBJECT - TYPE 3 - STANDING ORDER
  public initStandingOrderWorkObjectPreviewDOM(
    standingOrderWorkObjectPreviewDTO: IWHStandingOrderWorkObjectPreviewDTO,
  ): WHStandingOrderWorkObjectPreviewDOM {
    return new WHStandingOrderWorkObjectPreviewDOM(
      standingOrderWorkObjectPreviewDTO,
    );
  }

  public initStandingOrderWorkObjectDOM(
    standingOrderWorkObjectDTO: IWHStandingOrderWorkObjectDTO,
  ): WHStandingOrderWorkObjectDOM {
    return new WHStandingOrderWorkObjectDOM(standingOrderWorkObjectDTO);
  }

  // WORK OBJECT TEMPLATE
  public initWorkObjectTemplateDOM(
    workObjectTemplateDTO: IWHWorkObjectTemplateDTO,
  ): WHWorkObjectTemplateDOM {
    return new WHWorkObjectTemplateDOM(workObjectTemplateDTO);
  }

  public initWorkObjectTemplatePreviewDOM(
    workObjectTemplatePreviewDTO: IWHWorkObjectTemplatePreviewDTO,
  ): WHWorkObjectTemplatePreviewDOM {
    return new WHWorkObjectTemplatePreviewDOM(workObjectTemplatePreviewDTO);
  }

  // WORK ORDER
  public initWorkOrderDOM(workOrderDTO: IWHWorkOrderDTO): WHWorkOrderDOM {
    return new WHWorkOrderDOM(workOrderDTO);
  }

  // WORK ORDER FILE
  public initWorkOrderFileDOM(
    workOrderFileDTO: IWHWorkOrderFileDTO,
  ): WHFileDOM {
    return new WHFileDOM().initWorkOrderFileDOM(workOrderFileDTO);
  }

  // WORK STEP
  public initWorkStepDOM(workStepDTO: IWHWorkStepDTO): WHWorkStepDOM {
    return new WHWorkStepDOM(workStepDTO);
  }

  // ENTRIES
  public initEntryPreviewDOM(
    entryPreviewDTO: IWHEntryPreviewDTO,
  ): WHEntryPreviewDOM {
    return new WHEntryPreviewDOM(entryPreviewDTO);
  }

  public initEntryDOM(entryDTO: IWHEntryDTO): WHEntryDOM {
    return new WHEntryDOM(entryDTO);
  }

  public initEntryDomByEntryTypeId(entryDTO: IWHEntryDTO) {
    switch (entryDTO.entryTypeId) {
      case 1:
        return this.initTravelEntryDOM(entryDTO as IWHTravelEntryDTO);
      case 2:
        return this.initWorkingTimeEntryDOM(entryDTO as IWHWorkingTimEntryDTO);
      case 3:
        return this.initExpenceEntryDOM(entryDTO as IWHExpenseEntryDTO);
      case 4:
        return this.initDefectEntryDOM(entryDTO as IWHDefectEntryDTO);
      case 5:
        return this.initMaterialConsumptionEntryDOM(
          entryDTO as IWHMaterialConsumptionEntryDTO,
        );
      case 6:
        return this.initMeasurmentEntryDOM(entryDTO as IWHMeasurementEntryDTO);
      default:
        return undefined;
    }
  }

  // ENTRY - TYPE 1
  public initTravelEntryDOM(
    travelEntryDTO: IWHTravelEntryDTO,
  ): WHTravelEntryDOM {
    return new WHTravelEntryDOM(travelEntryDTO);
  }

  // ENTRY - TYPE 2
  public initWorkingTimeEntryDOM(
    workingTimeEntryDTO: IWHWorkingTimEntryDTO,
  ): WHWorkingTimEntryDOM {
    return new WHWorkingTimEntryDOM(workingTimeEntryDTO);
  }

  // ENTRY - TYPE 3
  public initExpenceEntryDOM(
    expenceEntryDTO: IWHExpenseEntryDTO,
  ): WHExpenseEntryDOM {
    return new WHExpenseEntryDOM(expenceEntryDTO);
  }

  // ENTRY - TYPE 4
  public initDefectEntryDOM(
    defectEntryDTO: IWHDefectEntryDTO,
  ): WHDefectEntryDOM {
    return new WHDefectEntryDOM(defectEntryDTO);
  }

  // ENTRY - TYPE 5
  public initMaterialConsumptionEntryDOM(
    materialConsumptionEntryDTO: IWHMaterialConsumptionEntryDTO,
  ): WHMaterialConsumptionEntryDOM {
    return new WHMaterialConsumptionEntryDOM(materialConsumptionEntryDTO);
  }

  // ENTRY - TYPE 6
  public initMeasurmentEntryDOM(
    measurmentEntryDTO: IWHMeasurementEntryDTO,
  ): WHMeasurementEntryDOM {
    return new WHMeasurementEntryDOM(measurmentEntryDTO);
  }

  // ENTRY FILE
  public initEntryFileDOM(entryFileDTO: IWHEntryFileDTO): WHEntryFileDOM {
    return new WHEntryFileDOM(entryFileDTO);
  }

  public initEntryFilePreviewDOM(
    entryFilePreviewDTO: IWHEntryFilePreviewDTO,
  ): WHEntryFilePreviewDOM {
    return new WHEntryFilePreviewDOM(entryFilePreviewDTO);
  }

  // REPORT FILE
  public initReportFilePreviewDOM(
    reportFilePreviewDTO: IWHReportFilePreviewDTO,
  ): WHReportFilePreviewDOM {
    return new WHReportFilePreviewDOM(reportFilePreviewDTO);
  }
}
