<mat-chip-listbox
  [ngStyle]="{ 'max-height': maxHeight() }"
  style="overflow-y: auto"
  [ngClass]="{
    'mat-mdc-chip-set-stacked': isStacked(),
    'scroll-background': showScrollBackground(),
  }"
>
  <mat-chip
    *ngFor="let item of data(); let i = index"
    class="wh-chip-{{ i }}"
    [matMenuTriggerFor]="menuItems().length > 0 ? menu : null"
    (click)="handleNavigate(item)"
    [disabled]="isDisabled() || isLoading()"
    [matTooltip]="getTooltip(item)"
  >
    <img
      *ngIf="
        imageKey() &&
          item[imageKey()] &&
          (item[imageKey()]
            | WHImgAuthHeader: placeholderImg()
            | async) as image;
        else placeholderimg
      "
      alt="asyncImage"
      [src]="image"
      matChipAvatar
    />
    <ng-template #placeholderimg>
      <mat-icon matChipAvatar *ngIf="matIcon()">{{ matIcon() }}</mat-icon>
      <i
        *ngIf="icon()"
        matChipAvatar
        class="workheld-icons wh-icon-scale"
        [innerHTML]="item['icon'] ? item['icon'] : icon()"
      ></i>
      <img
        *ngIf="placeholderImg()"
        [src]="placeholderImg()"
        alt="matChipAvatar"
        matChipAvatar
      />
    </ng-template>

    <ng-container *ngIf="titleKey()">
      {{ item[titleKey()] }}
    </ng-container>
    <div
      matChipRemove
      class="chip-remove-container"
      [style.width.px]="
        isRemoveButtonVisible() || suffixIcon()
          ? getBadge(item)
            ? 44
            : 20
          : getBadge(item)
            ? 20
            : 0
      "
    >
      <span class="skill-count" *ngIf="getBadge(item)">
        {{ getBadge(item) }}
      </span>

      <mat-icon
        style="z-index: 999"
        class="remove-icon-style remove-chip-button"
        *ngIf="(isRemoveButtonVisible() && !isLoading()) || suffixIcon()"
        [hidden]="!isRemoveButtonVisible() || !item.isOfBomNode"
        (click)="onRemoveChip($event, item)"
      >
        {{ suffixIcon() ?? "cancel" }}
      </mat-icon>

      <mat-spinner
        [diameter]="15"
        class="remove-icon-style remove-chip-button"
        *ngIf="
          isRemoveButtonVisible() && isLoading() && removingChip() === item
        "
        [hidden]="!isRemoveButtonVisible() || !item.isOfBomNode"
      >
      </mat-spinner>
    </div>

    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let menuItem of menuItems()"
        (click)="onMenuItemClick(item, menuItem.actionKey)"
        [disabled]="
          (menuItem?.disableById
            ? item?.id === menuItem?.disableById
            : false && menuItem?.disableByObjectKey
              ? !item[menuItem.disableByObjectKey]
              : false) ||
          (menuItem?.disableByObjectKey
            ? !item[menuItem.disableByObjectKey]
            : false)
        "
      >
        {{ menuItem.translateKey | translate }}
      </button>
    </mat-menu>
  </mat-chip>
</mat-chip-listbox>
