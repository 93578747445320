import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, highlightObj: Record<string, string>): string {
    if (!text || !highlightObj) return text;

    let result = text;

    Object.entries(highlightObj).forEach(([key, value]) => {
      if (value) {
        // Escape special characters to prevent regex issues
        const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Find the last occurrence of the value
        const lastIndex = result.toLowerCase().lastIndexOf(value.toLowerCase());

        if (lastIndex !== -1) {
          // Replace only the last occurrence
          result =
            result.substring(0, lastIndex) +
            `<span class="highlight">${result.substring(lastIndex, lastIndex + value.length)}</span>` +
            result.substring(lastIndex + value.length);
        }
      }
    });

    return result;
  }
}
