import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  DateTimeService,
  materialStatusIdEnumArray,
} from '@workheld/workheld-shared-lib';
import { deriveTimeFormat } from 'time-input-formatter';

@Component({
  selector: 'app-shift-model-fields',
  templateUrl: './shift-model-fields.component.html',
  styleUrl: './shift-model-fields.component.scss',
})
export class ShiftModelFieldsComponent implements OnInit {
  statusIdEnumArray: string[] = materialStatusIdEnumArray;

  timeIntervals: string[] = [];

  @Input() public shiftModelsFormGroup: FormGroup;
  @Input() public canDelete: boolean = false;
  @Output() public onDelete: EventEmitter<void> = new EventEmitter<void>();

  constructor(private dateTimeHelperService: DateTimeService) {}

  ngOnInit(): void {
    this.timeIntervals =
      this.dateTimeHelperService.initTimeOptionDropdownArray(30);
  }

  onBlur(event, timeType: string) {
    if (event.type === 'onClickOutside') return;
    const control = this.shiftModelsFormGroup.get(timeType);
    if (control) {
      const formattedTime = deriveTimeFormat(control.value, '24hm');
      if (formattedTime.valid) {
        control.setValue(formattedTime.value);
      }
    }
  }

  deleteShiftModel() {
    this.onDelete.emit();
    // this.shiftModels.removeAt(modelIndex);
  }
}
