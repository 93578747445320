import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
const defaultAnimationLength = 550;
export const animationLength = (multiplier: number = 1) =>
  `${defaultAnimationLength * multiplier}ms`;

export const dynamicAnimation = trigger('dynamicAnimation', [
  state('up', style({ transform: 'translateY(0)', opacity: 1 })),
  state('right', style({ transform: 'translateX(0)', opacity: 1 })),
  state('down', style({ transform: 'translateY(0)', opacity: 1 })),
  state('left', style({ transform: 'translateX(0)', opacity: 1 })),
  state('downLeave', style({ transform: 'translateY(0)', opacity: 1 })),

  transition('* => up', [
    style({
      transform: 'translateY(20px)',
      opacity: 0.6,
    }),
    animate(
      `${animationLength()} cubic-bezier(0.25, 1, 0.5, 1)`,
      style({ transform: 'translateY(0)', opacity: 1 }),
    ),
  ]),
  transition('* => right', [
    style({ transform: 'translateX(-20px)', opacity: 0.6 }),
    animate(
      `${animationLength()} cubic-bezier(0.25, 1, 0.5, 1)`,
      style({ transform: 'translateX(0)', opacity: 1 }),
    ),
  ]),
  transition('* => down', [
    style({ transform: 'translateY(-20px)', opacity: 0.6 }),
    animate(
      `${animationLength()} cubic-bezier(0.25, 1, 0.5, 1)`,
      style({ transform: 'translateY(0)', opacity: 1 }),
    ),
  ]),
  transition('* => left', [
    style({ transform: 'translateX(20px)', opacity: 0.6 }),
    animate(
      `${animationLength()} cubic-bezier(0.25, 1, 0.5, 1)`,
      style({ transform: 'translateX(0)', opacity: 1 }),
    ),
  ]),
  transition('* => downLeave', [
    style({ transform: 'translateY(0)', opacity: 1 }),
    animate(
      `${animationLength()} cubic-bezier(0.25, 1, 0.5, 1)`,
      style({ transform: 'translateY(200px)', opacity: 0.6 }),
    ),
  ]),
]);
